import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';

import { store } from './store';
import ROUTES from './routes.constants';
import Users from './routes/Users';
import UserForm from './routes/UserForm';
import StreamingDevices from './routes/StreamingDevices';
import StreamingDeviceForm from './routes/StreamingDeviceForm';
import Profile from './routes/Profile';
import Organizations from './routes/Organizations';
import OrganizationForm from './routes/OrganizationForm';
import LoginSpecialEvent from './routes/LoginSpecialEvent';
import LoginPage from './routes/Login';
import LearnMore from './routes/LearnMore';
import ForgotPassword from './routes/ForgotPassword';
import Events from './routes/Events';
import EventForm from './routes/EventForm';
import DashboardSpecialEvent from './routes/DashboardSpecialEvent';
import Dashboard from './routes/Dashboard';
import CreatePassword from './routes/CreatePassword';
import Admin from './routes/Admin';
import UnauthenticatedWrapper from './components/Wrappers/UnauthenticatedWrapper';
import SpecialEventWrapper from './components/Wrappers/SpecialEventWrapper';
import AuthenticatedWrapper from './components/Wrappers/AuthenticatedWrapper';

const router = createBrowserRouter([
  {
    element: <AuthenticatedWrapper />,
    children: [
      {
        path: ROUTES.DASHBOARD,
        element: <Dashboard />
      },
      {
        path: `${ROUTES.DASHBOARD}/:id`,
        element: <Dashboard />
      },
      {
        path: ROUTES.PROFILE,
        element: <Profile />
      },
      {
        element: <Admin />,
        path: ROUTES.ADMIN,
        children: [
          {
            index: true,
            element: <Navigate to={ROUTES.ORGANIZATIONS} />
          },
          {
            path: ROUTES.ORGANIZATIONS,
            element: <Organizations />
          },
          {
            path: ROUTES.ORGANIZATION_EDIT,
            element: <OrganizationForm />
          },
          {
            path: ROUTES.ORGANIZATION_CREATE,
            element: <OrganizationForm />
          },
          {
            path: ROUTES.ORGANIZATION_EDIT,
            element: <OrganizationForm />
          },
          {
            path: ROUTES.USERS,
            element: <Users />
          },
          {
            path: ROUTES.USER_EDIT,
            element: <UserForm />
          },
          {
            path: ROUTES.USER_CREATE,
            element: <UserForm />
          },
          {
            path: ROUTES.STREAMING_DEVICES,
            element: <StreamingDevices />
          },
          {
            path: ROUTES.STREAMING_DEVICES_CREATE,
            element: <StreamingDeviceForm />
          },
          {
            path: ROUTES.STREAMING_DEVICES_EDIT,
            element: <StreamingDeviceForm />
          },
          {
            path: ROUTES.EVENTS,
            element: <Events />
          },
          {
            path: ROUTES.EVENT_CREATE,
            element: <EventForm />
          },
          {
            path: ROUTES.EVENT_EDIT,
            element: <EventForm />
          }
        ]
      },
      {
        path: '*',
        element: <Navigate to={ROUTES.DASHBOARD} replace />
      }
    ]
  },
  {
    element: <UnauthenticatedWrapper />,
    children: [
      {
        path: ROUTES.LOGIN,
        element: <LoginPage />
      },
      {
        path: ROUTES.FORGOT_PASSWORD,
        element: <ForgotPassword />
      },
      {
        path: ROUTES.LEARN_MORE,
        element: <LearnMore />
      },
      {
        path: ROUTES.CREATE_PASSWORD,
        element: <CreatePassword />
      }
    ]
  },
  {
    element: <SpecialEventWrapper />,
    children: [
      {
        path: ROUTES.EVENT_LOGIN,
        element: <LoginSpecialEvent />
      },
      {
        path: ROUTES.SPECIAL_EVENT,
        element: <DashboardSpecialEvent />
      }
    ]
  }
]);

const App = () => (
  <>
    <ReduxProvider store={store}>
      <RouterProvider router={router} />
    </ReduxProvider>
  </>
);

export default App;
