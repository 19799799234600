import { ColumnsType } from 'rc-table/lib/interface';

import { StreamingDevice } from '../../types/streamingDevices';
import ROUTES from '../../routes.constants';
import Checkbox from '../../components/Checkbox/Checkbox';
import TableActions from '../../components/CNCTable/components/TableActions';
import SortableColumn from '../../components/CNCTable/components/SortableColumn';

import { regionOptions } from './deviceOptions';

export type OnFieldChangeParams = {
  record: StreamingDevice;
  key: keyof StreamingDevice;
  value: string | number | boolean;
};

type StreamingDevicesColumnsParams = {
  onFieldChange: (params: OnFieldChangeParams) => void;
  isChanging: (id: string) => boolean;
  onDelete: (id: string) => void;
  isVar: boolean;
};

export const streamingDevicesColumns = ({
  onFieldChange,
  isChanging,
  onDelete,
  isVar
}: StreamingDevicesColumnsParams): ColumnsType<StreamingDevice> => [
  {
    title: 'Viewable',
    dataIndex: 'viewable',
    align: 'center',
    width: 100,
    render: (checked, record) => (
      <Checkbox
        checked={checked}
        centered
        disabled={isChanging(record.id) || !isVar}
        onChange={(value) => onFieldChange({ record, key: 'viewable', value })}
      />
    )
  },
  {
    title: <SortableColumn<StreamingDevice> label="Streaming Devices" sortKey="name" />,
    dataIndex: 'name',
    align: 'center',
    width: 200
  },
  {
    title: <SortableColumn<StreamingDevice> label="Organization" sortKey="organization" />,
    align: 'center',
    width: 180,
    render: ({ organization }) => organization?.name
  },
  {
    title: <SortableColumn<StreamingDevice> label="Device Type" sortKey="device_type" />,
    align: 'center',
    dataIndex: 'device_type',
    width: 180
  },
  {
    title: <SortableColumn<StreamingDevice> label="Region" sortKey="region" />,
    align: 'center',
    width: 180,
    render: ({ region }) => regionOptions.find((reg) => reg.value === region)?.label || '-'
  },
  {
    title: 'Email notifications',
    dataIndex: 'email_notifications',
    align: 'center',
    width: 190,
    render: (checked, record) => (
      <Checkbox
        checked={checked}
        centered
        disabled={isChanging(record.id)}
        onChange={(value) => onFieldChange({ record, key: 'email_notifications', value })}
      />
    )
  },
  {
    title: 'In-page notifications',
    dataIndex: 'in_app_notifications',
    align: 'center',
    width: 190,
    render: (checked, record) => (
      <Checkbox
        checked={checked}
        centered
        disabled={isChanging(record.id)}
        onChange={(value) => onFieldChange({ record, key: 'in_app_notifications', value })}
      />
    )
  },
  {
    title: null,
    align: 'center',
    width: 190,
    render: ({ id }) => (
      <TableActions
        id={id}
        editUrl={ROUTES.STREAMING_DEVICES_EDIT.replace(':id', id)}
        onDelete={isVar ? () => onDelete(id) : undefined}
      />
    )
  }
];
