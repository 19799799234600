import React, { FC, useEffect, useMemo, useState } from 'react';

import Button from '../Button';
import { ReactComponent as NextIcon } from '../../assets/next-icon.svg';
import { ReactComponent as NextEndIcon } from '../../assets/next-end-icon.svg';
import { ReactComponent as BackStartIcon } from '../../assets/back-start-icon.svg';
import { ReactComponent as BackIcon } from '../../assets/back-icon.svg';

import './Pagination.scss';

type Props = {
  pageNumber?: number;
  pageSize?: number;
  total?: number;
  onChange: (page: number) => void;
};

const Pagination: FC<Props> = ({ pageNumber = 0, pageSize = 10, total = 0, onChange }) => {
  const [currentPage, setCurrentPage] = useState(pageNumber);
  const totalPages = useMemo(() => Math.ceil(total / pageSize), [total, pageSize]);
  const currentFirstIndex = useMemo(() => currentPage * pageSize + 1, [currentPage, pageSize]);
  const currentLastIndex = useMemo(
    () => Math.min((currentPage + 1) * pageSize, total),
    [currentPage, pageSize, total]
  );
  const isPrevDisabled = useMemo(() => currentPage <= 0, [currentPage]);
  const isNextDisabled = useMemo(() => currentPage >= totalPages - 1, [currentPage, totalPages]);

  const handlePageChange = (value: number) => {
    setCurrentPage(value);
    onChange(value);
  };

  useEffect(() => {
    setCurrentPage(pageNumber);
  }, [total, pageNumber]);

  return (
    <div className="pagination-container">
      <div className="pagination-prev">
        <Button
          onClick={() => handlePageChange(0)}
          className="pagination-button"
          disabled={isPrevDisabled}
        >
          <BackStartIcon />
        </Button>
        <Button
          onClick={() => handlePageChange(currentPage - 1)}
          className="pagination-button"
          disabled={isPrevDisabled}
        >
          <BackIcon />
        </Button>
      </div>

      <div className="pagination-values-container">
        <span className="pagination-value">
          {!total ? total : currentFirstIndex}-{currentLastIndex}
        </span>
        <span className="pagination-text">of</span>
        <span className="pagination-value">{total}</span>
      </div>

      <div className="pagination-next">
        <Button
          onClick={() => handlePageChange(currentPage + 1)}
          className="pagination-button"
          disabled={isNextDisabled}
        >
          <NextIcon />
        </Button>
        <Button
          onClick={() => handlePageChange(totalPages - 1)}
          className="pagination-button"
          disabled={isNextDisabled}
        >
          <NextEndIcon />
        </Button>
      </div>
    </div>
  );
};

export default Pagination;
