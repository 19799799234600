import React, { FC, ReactNode, useState } from 'react';

import { Sort, TableRecord } from '../helpers';

import CNCTableContext from './CNCTable.context';

interface ProviderProps {
  children: ReactNode;
  data?: unknown[];
}

const CNCTableProvider: FC<ProviderProps> = ({ children, data }) => {
  const [sort, setSort] = useState<Sort<TableRecord>>({});
  const [selection, setSelection] = useState<TableRecord[]>([]);

  return (
    <CNCTableContext.Provider
      value={{
        sort,
        selection,
        setSort,
        setSelection,
        data
      }}
    >
      {children}
    </CNCTableContext.Provider>
  );
};

export default CNCTableProvider;
