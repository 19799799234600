import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import React, { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';

import {
  deviceTypeOptions,
  regionOptions,
  protocolTypeOptions
} from '../StreamingDevices/deviceOptions';
import { ROLES } from '../../types/users';
import { RegionType, StreamingDeviceType, ProtocolType } from '../../types/streamingDevices';
import { OrganizationNameAndID } from '../../types/organizations';
import { ErrorResponse } from '../../types';
import { selectCurrentUser } from '../../store/user/selectors';
import {
  useCreateStreamingDeviceMutation,
  useEditStreamingDeviceMutation,
  useGetStreamingDeviceQuery
} from '../../store/services/streamingDevicesApi';
import { useOrganizationsQuery } from '../../store/services/organizationsApi';
import { handleCatch } from '../../store/services/helpers';
import ROUTES from '../../routes.constants';
import useTitle from '../../helpers/useTitle';
import Select from '../../components/Select';
import Loader from '../../components/Loader';
import InputField from '../../components/Input';
import ConfirmModal from '../../components/ConfirmModal';
import Button from '../../components/Button';

import { CheckboxType } from './CheckboxGroup/CheckboxGroup';
import CheckboxGroup from './CheckboxGroup';

import './StreamingDeviceForm.scss';

const StreamingDeviceForm: FC = () => {
  const currentUser = useSelector(selectCurrentUser);
  const { id } = useParams();
  const navigate = useNavigate();
  useTitle('Streaming Device');

  const isVar = useMemo(() => currentUser?.role === ROLES.VAR, [currentUser]);

  const { data: dataStreamingDevice, isLoading: isLoadingStreamingDevice } =
    useGetStreamingDeviceQuery({ id: id || '' }, { skip: !id });

  const { data: organizations } = useOrganizationsQuery(
    { q: 'active:true' },
    {
      skip: currentUser?.role !== ROLES.VAR
    }
  );
  const [createStreamingDevice, { isLoading: isLoadingCreate, isError: isErrorCreate }] =
    useCreateStreamingDeviceMutation();
  const [editStreamingDevice, { isLoading: isLoadingEdit, isError: isErrorEdit }] =
    useEditStreamingDeviceMutation();

  const [isErrorEventModalOpen, setIsErrorEventModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [deviceType, setDeviceType] = useState<StreamingDeviceType | undefined>();
  const [organization, setOrganization] = useState<OrganizationNameAndID | undefined>({
    name: '',
    id: ''
  });
  const [inAppNotifications, setInAppNotifications] = useState(true);
  const [emailNotifications, setEmailNotifications] = useState(false);
  const [viewable, setViewable] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [region, setRegion] = useState<RegionType>('east-1');

  const [protocolType, setProtocolType] = useState<ProtocolType>('rtmp');

  const isError = useMemo(() => isErrorCreate || isErrorEdit, [isErrorCreate, isErrorEdit]);

  const isLoading = useMemo(
    () => isLoadingStreamingDevice || isLoadingCreate || isLoadingEdit,
    [isLoadingStreamingDevice, isLoadingCreate, isLoadingEdit]
  );

  const organizationOptions = useMemo(
    () =>
      currentUser?.role === ROLES.VAR
        ? organizations?.items.map((org) => ({
            value: org.id,
            label: org.name
          })) || []
        : [
            {
              label: currentUser?.organization.name || '',
              value: currentUser?.organization.id || ''
            }
          ],
    [organizations, currentUser]
  );

  useEffect(() => {
    if (isError) {
      setIsErrorEventModalOpen(true);
    }
  }, [isError]);

  useEffect(() => {
    if (dataStreamingDevice) {
      setName(dataStreamingDevice.name);
      setDescription(dataStreamingDevice.description);
      setInAppNotifications(dataStreamingDevice.in_app_notifications);
      setEmailNotifications(dataStreamingDevice.email_notifications);
      setViewable(dataStreamingDevice.viewable);
      setDeviceType(dataStreamingDevice.device_type);
      setRegion(dataStreamingDevice.region);

      setProtocolType(dataStreamingDevice.protocol);

      const currentOrg = organizations?.items.find(
        (org) => org.id === dataStreamingDevice?.organization?.id
      );

      if (currentOrg) {
        setOrganization({
          id: currentOrg.id,
          name: currentOrg.name
        });
      }
    } else if (!dataStreamingDevice && currentUser?.role === ROLES.LOCAL_ADMIN) {
      setOrganization({
        id: currentUser.organization.id,
        name: currentUser.organization.name
      });
    }
  }, [dataStreamingDevice, organizations]);

  const resetForm = () => {
    setName('');
    setDescription('');
    setRegion('east-1');
    setProtocolType('rtmp');
    setDeviceType(undefined);
    setOrganization({ name: '', id: '' });
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);

    if (!id) resetForm();
  };

  const handleConfirm = () => {
    navigate(ROUTES.STREAMING_DEVICES);
  };

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newName = e.target.value;
    setName(newName);
  };

  const handleCompanyChange = (
    newValue: string | number | boolean | (string | number | boolean)[] | undefined
  ) => {
    const selectedOrganization = organizationOptions.find((org) => org.value === newValue);

    setOrganization({
      id: newValue as string,
      name: selectedOrganization?.label || ''
    });
  };

  const handleDeviceTypeChange = (value: StreamingDeviceType) => {
    setDeviceType(value);
  };

  const handleRegionChange = (value: RegionType) => {
    setRegion(value);
  };

  const handleProtocolTypeChange = (value: ProtocolType) => {
    setProtocolType(value);
  };

  const handleDescriptionChange = (e: ChangeEvent<HTMLInputElement>) => {
    setDescription(e.target.value);
  };

  const handleCheckboxChange = (type: CheckboxType, value: boolean) => {
    if (type === 'inApp') {
      setInAppNotifications(value);
    } else if (type === 'email') {
      setEmailNotifications(value);
    } else if (type === 'viewable') {
      setViewable(value);
    }
  };

  const handleSubmit = async () => {
    try {
      const deviceData = {
        name,
        description,
        organization_id: organization?.id,
        viewable,
        in_app_notifications: inAppNotifications,
        email_notifications: emailNotifications,
        device_type: deviceType,
        region
      };

      if (!id) {
        await createStreamingDevice({ protocol: protocolType, ...deviceData }).unwrap();
      } else {
        await editStreamingDevice({ id, ...deviceData }).unwrap();
      }

      setIsModalOpen(true);
    } catch (error) {
      const errorMessage = (error as ErrorResponse)?.data?.message;

      setErrorMessage(errorMessage);
      handleCatch(error);
    }
  };

  const handleCancel = () => {
    if (id && dataStreamingDevice) {
      setName(dataStreamingDevice.name);
      setDescription(dataStreamingDevice.description);
      setDeviceType(dataStreamingDevice.device_type);
      setRegion(dataStreamingDevice.region);
      setOrganization({
        id: dataStreamingDevice.organization?.id,
        name:
          organizations?.items.find((org) => org.id === dataStreamingDevice.organization?.id)
            ?.name || ''
      });
    } else {
      resetForm();
    }
  };

  const handleErrorEvent = () => {
    setIsErrorEventModalOpen(false);
    setErrorMessage('');
  };

  const isDataModified = useMemo(
    () =>
      name !== dataStreamingDevice?.name ||
      description !== dataStreamingDevice?.description ||
      organization?.id !== dataStreamingDevice?.organization?.id ||
      viewable !== dataStreamingDevice?.viewable ||
      deviceType !== dataStreamingDevice?.device_type ||
      inAppNotifications !== dataStreamingDevice?.in_app_notifications ||
      emailNotifications !== dataStreamingDevice?.email_notifications ||
      region !== dataStreamingDevice?.region,
    [
      name,
      description,
      region,
      organization,
      inAppNotifications,
      emailNotifications,
      deviceType,
      viewable,
      dataStreamingDevice
    ]
  );

  const shouldEnableSaveChanges = useMemo(
    () =>
      (id && isDataModified) ||
      (!id && name && description && organization?.id && isDataModified && deviceType),
    [isDataModified, name, description, deviceType, organization, id]
  );

  const shouldEnableCancel = useMemo(
    () =>
      (id && isDataModified) || (!id && (name || description || organization?.id || deviceType)),
    [id, isDataModified, name, description, organization]
  );

  if (isLoading) return <Loader />;

  return (
    <div className="streaming-device-form">
      <div className="streaming-device-form-title-container">
        <h1 className="streaming-device-form-title">{id ? 'Edit' : 'Create'} Streaming Device</h1>
        <NavLink to={ROUTES.STREAMING_DEVICES} className="streaming-device-form-link">
          Back
        </NavLink>
      </div>

      <div className="streaming-device-form-inputs-wrapper">
        <div className="streaming-device-form-inputs-container">
          <h5 className="streaming-device-form-section-title">Information</h5>
          <InputField
            type="text"
            className="streaming-device-form-input"
            value={name}
            disabled={!isVar}
            onChange={handleNameChange}
            id="name"
            label="Name *"
          />

          <Select
            disabled={!isVar}
            options={organizationOptions}
            value={organization?.id}
            onChange={handleCompanyChange}
            id="organization"
            label="Organization *"
          />

          <Select
            disabled={!isVar}
            options={deviceTypeOptions}
            value={deviceType}
            onChange={(value) => handleDeviceTypeChange(value as StreamingDeviceType)}
            id="device-type"
            label="Device Type *"
          />

          <Select
            disabled={!isVar || !!id}
            options={regionOptions}
            value={region}
            onChange={(value) => handleRegionChange(value as RegionType)}
            id="region"
            label="Region *"
          />

          <Select
            disabled={!isVar || !!id}
            options={protocolTypeOptions}
            value={protocolType}
            onChange={(value) => handleProtocolTypeChange(value as ProtocolType)}
            id="protocol-type"
            label="Protocol Type *"
          />

          <InputField
            disabled={!isVar}
            type="text"
            className="streaming-device-form-input"
            value={description}
            onChange={handleDescriptionChange}
            id="description"
            label="Description *"
          />

          {id && dataStreamingDevice && (
            <InputField
              disabled
              type="text"
              className="streaming-device-form-input"
              value={dataStreamingDevice.entry_point}
              id="entry-point"
              label="Static Entry Point"
              isCopyable
            />
          )}

          <CheckboxGroup
            disabled={!isVar}
            viewable={viewable}
            inAppNotifications={inAppNotifications}
            emailNotifications={emailNotifications}
            handleCheckboxChange={handleCheckboxChange}
          />
        </div>

        <div className="streaming-device-form-buttons-container">
          <Button variant="primary" disabled={!shouldEnableSaveChanges} onClick={handleSubmit}>
            Save Changes
          </Button>
          <Button variant="secondary" onClick={handleCancel} disabled={!shouldEnableCancel}>
            Cancel
          </Button>
        </div>
      </div>

      {isError && (
        <ConfirmModal
          isOpen={isErrorEventModalOpen}
          onConfirm={handleErrorEvent}
          message={errorMessage}
          confirmText="Ok"
          variant="primary"
        />
      )}

      <ConfirmModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onConfirm={handleConfirm}
        message={`Streaming Device successfully ${id ? 'modified' : 'created'}`}
        cancelText="Stay on this page"
        confirmText="Go to all devices"
        variant="primary"
      />
    </div>
  );
};

export default StreamingDeviceForm;
