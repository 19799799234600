import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

const MANUAL_SCROLLING_PATHS: string[] = [
  '^/[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}'
];

const ScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    for (const path of MANUAL_SCROLLING_PATHS) {
      if (new RegExp(path, 'gi').test(pathname)) {
        return;
      }
    }

    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [pathname]);

  return null;
};

export default ScrollToTop;
