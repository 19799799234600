import { Context, createContext, useContext } from 'react';

import { Sort, TableRecord } from '../helpers';

export interface TableContextProps<T = TableRecord> {
  selection: T[];
  setSelection: (selection: T[]) => void;
  sort: Sort<T>;
  setSort: (sort: Sort<T>) => void;
  data?: unknown[];
}

const TableContext = createContext<TableContextProps>({
  selection: [],
  setSelection: () => [],
  sort: {},
  setSort: () => ({}),
  data: []
});

export const useTableContext = <T>(): TableContextProps<T> => {
  const context = useContext<T>(TableContext as unknown as Context<T>);

  if (!context) {
    throw new Error('useTableContext must be used within a CNCTableProvider');
  }

  return context as unknown as TableContextProps<T>;
};

export default TableContext;
