import { ApiListRequestParams } from '../../types/api';
import isFetchBaseQueryError from '../../helpers/isFetchBaseQueryError';

export function buildQueryParams(baseUrl: string, params: ApiListRequestParams): string {
  const queryChain = [];

  // Add query parameters
  for (const [key, value] of Object.entries(params)) {
    if (value) {
      queryChain.push(`${key}=${value}`);
    }
  }

  const finalChain = queryChain ? `?${queryChain.join('&')}` : '';

  return `${baseUrl}${finalChain}`;
}

export const handleCatch = (e: unknown) => {
  if (isFetchBaseQueryError(e)) {
    // eslint-disable-next-line no-console
    console.error('Error:', e);
  }
};

export const generateRandomHash = (): string => {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let hash = '';

  for (let i = 0; i < 8; i++) {
    const randomIndex = Math.floor(Math.random() * chars.length);
    hash += chars[randomIndex];
  }

  return hash;
};

export const isNullOrUndefinedOrEmpty = (value: unknown): boolean =>
  value === null ||
  value === undefined ||
  (typeof value === 'string' && value.length === 0) ||
  (Array.isArray(value) && value.length === 0);

export const formatExpiry = (isoDateString: string): string => {
  const expiryDate = new Date(isoDateString).getTime();
  const now = new Date().getTime();
  const diff = expiryDate - now;

  if (diff <= 0) {
    return '';
  }

  const days = Math.floor(diff / (24 * 3600 * 1000));
  const hours = Math.floor((diff % (24 * 3600 * 1000)) / (3600 * 1000));
  const minutes = Math.floor((diff % (3600 * 1000)) / (60 * 1000));
  const seconds = Math.floor((diff % (60 * 1000)) / 1000);

  const timeSegments = [];

  if (days > 0) timeSegments.push(`${days} d`);

  if (hours > 0 || (days > 0 && minutes > 0)) timeSegments.push(`${hours} hr`);

  if (minutes > 0 || (hours > 0 && seconds > 0)) timeSegments.push(`${minutes} m`);

  if (seconds > 0 && days === 0 && hours === 0 && minutes === 0) {
    timeSegments.push(`less than a minute`);
  }

  return `Expires in ${timeSegments.join(' ')}`;
};

// Returns Date.now() clamped to the given interval
// For instance, if `intervalLength` is 30000 all times returned within a 30 second period will have the same value
export const getIntervalClampedTime = (intervalLength = 30000) =>
  Math.floor(Date.now() / intervalLength) * intervalLength;
