import { ColumnsType } from 'rc-table/lib/interface';

import { StreamingDevice } from '../../types/streamingDevices';
import SortableColumn from '../../components/CNCTable/components/SortableColumn';

export const inactiveDevicesColumns = (): ColumnsType<StreamingDevice> => [
  {
    title: <SortableColumn<StreamingDevice> label="Name" sortKey="name" />,
    dataIndex: 'name',
    align: 'left',
    width: 100
  },
  {
    title: <SortableColumn<StreamingDevice> label="Device Type" sortKey="device_type" />,
    dataIndex: 'device_type',
    align: 'left',
    width: 100
  },
  {
    title: 'Description',
    dataIndex: 'description',
    align: 'left',
    width: 100
  }
];
