import { ColumnsType } from 'rc-table/lib/interface';

import { Organization } from '../../types/organizations';
import ROUTES from '../../routes.constants';
import TableActions from '../../components/CNCTable/components/TableActions';
import SortableColumn from '../../components/CNCTable/components/SortableColumn';
import BooleanCell from '../../components/CNCTable/components/BooleanCell';

export const organizationColumns: ColumnsType<Organization> = [
  {
    title: null,
    dataIndex: 'logo_url',
    align: 'center',
    render: (logo_url) => (
      <img src={logo_url} alt="organization profile" className="organization-logo" />
    ),
    width: 60
  },
  {
    title: <SortableColumn<Organization> label="Name" sortKey="name" />,
    dataIndex: 'name',
    align: 'left',
    width: 200
  },
  {
    title: <SortableColumn<Organization> label="Active" sortKey="active" />,
    dataIndex: 'active',
    align: 'center',
    render: (active) => <BooleanCell value={active} />,
    width: 100
  },
  {
    title: <SortableColumn<Organization> label="# Users" sortKey="number_of_users" />,
    dataIndex: 'number_of_users',
    align: 'center',
    width: 180
  },
  {
    title: <SortableColumn<Organization> label="# Streaming Devices" sortKey="number_of_streams" />,
    width: 180,
    dataIndex: 'number_of_streams',
    align: 'left'
  },
  {
    title: null,
    align: 'center',
    width: 120,
    render: ({ id }) => (
      <TableActions id={id} editUrl={ROUTES.ORGANIZATION_EDIT.replace(':id', id)} />
    )
  }
];
