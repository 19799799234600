import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { setIsLoggedIn } from '../user/slice';
import { User } from '../../types/users';
import { ApiListRequestParams, ApiListResponse } from '../../types/api';
import config from '../../config';

import { buildQueryParams, getIntervalClampedTime } from './helpers';

export type UserUpdateParams = Partial<User> & { id: string; organization_id?: string };
export type CreateUserParams = Omit<User, 'created_at' | 'updated_at' | 'id' | 'organization'> & {
  password: string;
  organization_id: string;
};

export const usersApi = createApi({
  reducerPath: 'usersApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${config.apiUrl}/users`,
    credentials: 'include'
  }),
  tagTypes: ['User', 'Users'],
  endpoints: (builder) => ({
    profile: builder.query<User, void>({
      query: () => `/me?_=${getIntervalClampedTime(1000)}`,
      async onQueryStarted(_, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
        } catch {
          dispatch(setIsLoggedIn(false));
        }
      },
      providesTags: ['User']
    }),
    users: builder.query<ApiListResponse<User>, ApiListRequestParams>({
      query: (params) => buildQueryParams('/search', params),
      providesTags: ['Users']
    }),
    getUserById: builder.query<User, { id: string | undefined }>({
      query: ({ id }) => `/${id}`,
      providesTags: ['User']
    }),
    editUser: builder.mutation<User, UserUpdateParams>({
      query: ({ id, ...userData }) => ({
        url: `/${id}`,
        method: 'PUT',
        body: userData
      }),
      invalidatesTags: ['User', 'Users']
    }),
    editProfile: builder.mutation<User, Partial<User>>({
      query: (userData) => ({
        url: '/me',
        method: 'PUT',
        body: userData
      }),
      invalidatesTags: ['User']
    }),
    createUser: builder.mutation<User, CreateUserParams>({
      query: (userData) => ({
        url: `/`,
        method: 'POST',
        body: userData
      }),
      invalidatesTags: ['Users']
    }),
    deleteUser: builder.mutation<User, { id: string }>({
      query: ({ id }) => ({
        url: `/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Users', 'User']
    }),
    reactivateUser: builder.mutation<User, { id: string }>({
      query: ({ id }) => ({
        url: `/${id}/activate`,
        method: 'PUT'
      }),
      invalidatesTags: ['Users', 'User']
    }),
    deactivateUser: builder.mutation<User, { id: string }>({
      query: ({ id }) => ({
        url: `/${id}/deactivate`,
        method: 'PUT'
      }),
      invalidatesTags: ['Users', 'User']
    })
  })
});

export const {
  useProfileQuery,
  useUsersQuery,
  useGetUserByIdQuery,
  useEditUserMutation,
  useEditProfileMutation,
  useCreateUserMutation,
  useDeleteUserMutation,
  useReactivateUserMutation,
  useDeactivateUserMutation
} = usersApi;
