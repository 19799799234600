import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

const isFetchBaseQueryError = (
  error: unknown
): error is FetchBaseQueryError & { data: { message: string; status?: string } } => {
  if (typeof error === 'object' && error !== null && 'status' in error && 'data' in error) {
    const errorWithMessage = error as FetchBaseQueryError & {
      data: { message?: string; status?: string };
    };

    return (
      typeof errorWithMessage.data?.message === 'string' ||
      typeof errorWithMessage.data?.status === 'string'
    );
  }

  return false;
};

export default isFetchBaseQueryError;
