import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import React, { FC, KeyboardEvent, useState } from 'react';

import { setIsLoggedIn } from '../../../store/user/slice';
import { selectIsLoggedIn } from '../../../store/user/selectors';
import { useAuthenticateUserMutation } from '../../../store/services/authApi';
import ROUTES from '../../../routes.constants';
import isFetchBaseQueryError from '../../../helpers/isFetchBaseQueryError';
import Loader from '../../../components/Loader';
import InputField from '../../../components/Input';
import ConfirmModal from '../../../components/ConfirmModal';
import Button from '../../../components/Button';
import logo from '../../../assets/logo.png';

import './LoginForm.scss';

const LoginForm: FC = () => {
  const [authenticateUser, { isLoading, isError }] = useAuthenticateUserMutation();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const [isRedirectModalOpen, setIsRedirectModalOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const from = location.state?.from?.pathname || ROUTES.DASHBOARD;

  const handleSubmit = async () => {
    try {
      const res = await authenticateUser({ username: email, password }).unwrap();
      localStorage.removeItem('currentOrg');

      if (res.status === 'NEW_PASSWORD_REQUIRED') {
        navigate(ROUTES.CREATE_PASSWORD, { state: { session: res.session, email } });
      } else {
        dispatch(setIsLoggedIn(true));
        navigate(from, { replace: true });
      }
    } catch (err) {
      if (isFetchBaseQueryError(err) && err.data) {
        const attemptsMatch = err.data.message.match(/Attempts: (\d+)/);
        const attempts = attemptsMatch ? parseInt(attemptsMatch[1], 10) : null;

        if (attempts && attempts >= 7) {
          const remainingAttempts = 10 - attempts;
          setErrorMessage(
            `You have ${remainingAttempts} more attempt${
              remainingAttempts > 1 ? 's' : ''
            } remaining`
          );
        }

        if (attempts && attempts >= 10) {
          navigate(ROUTES.FORGOT_PASSWORD);
        }

        if (
          err.data.message === 'Password reset required' ||
          err.data.message === 'BlockedUser error' ||
          err.data.message === 'Blocked user'
        ) {
          setIsRedirectModalOpen(true);
        }
      }
    }
  };

  const onConfirmRedirect = () => {
    setIsRedirectModalOpen(false);
    navigate(ROUTES.CREATE_PASSWORD, { state: { code: true, email } });
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  if (isLoggedIn) return null;

  return (
    <section className="login-form">
      <img src={logo} alt="logo" className="login-form-logo" />
      {isLoading && <Loader />}
      <h2>Welcome Back</h2>
      <h5>Log In to manage your account.</h5>

      <InputField
        className="login-form-email-input"
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        onKeyDown={handleKeyDown}
        id="email"
        label="Email"
      />

      <InputField
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        onKeyDown={handleKeyDown}
        id="password"
        label="Password"
        link={ROUTES.FORGOT_PASSWORD}
        linkText="Forgot Password"
        isPassword
      />

      <Button
        disabled={isLoading || !email || !password}
        onClick={handleSubmit}
        className="login-form-submit-button"
      >
        Log In
      </Button>

      <div className={`login-form-error-message ${isError ? 'login-form-visible' : ''}`}>
        {errorMessage || 'Invalid Email or Password'}
      </div>

      <div className="login-form-learn-more">
        Don&apos;t have an account?{' '}
        <Link to="/signup" className="link">
          Learn more
        </Link>
      </div>
      <ConfirmModal
        isOpen={isRedirectModalOpen}
        onConfirm={onConfirmRedirect}
        message="You seem to have forgotten your password. Will will direct you so you can create a new one. Please check your email for a code to enter on the Create New Password page."
        confirmText="Ok"
        variant="primary"
      />
    </section>
  );
};

export default LoginForm;
