import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { StreamingDevice } from '../../types/streamingDevices';
import { ApiListRequestParams, ApiListResponse } from '../../types/api';
import config from '../../config';

import { buildQueryParams, getIntervalClampedTime } from './helpers';

export const streamingDevicesApi = createApi({
  reducerPath: 'devicesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${config.apiUrl}/streams`,
    credentials: 'include'
  }),
  tagTypes: ['Devices', 'Device'],

  endpoints: (builder) => ({
    streamingDevices: builder.query<ApiListResponse<StreamingDevice>, ApiListRequestParams>({
      query: (params) => buildQueryParams('/search', params),
      providesTags: ['Devices']
    }),
    getStreamingDevice: builder.query<StreamingDevice, { id: string }>({
      query: ({ id }) => `/${id}`,
      providesTags: ['Device']
    }),
    getStreamingDevicesByOrganizationId: builder.query<
      ApiListResponse<StreamingDevice>,
      ApiListRequestParams & { organizationId: string | undefined; active: boolean }
    >({
      query: ({ organizationId, active, q, ...rest }) => {
        let query = `${
          organizationId !== 'all' ? `organization_id:${organizationId},` : ''
        }active:${active}`;

        if (q) {
          query = `${query},${q}`;
        }

        return buildQueryParams(
          `/search`,
          { ...rest, q: query, _: String(getIntervalClampedTime(10000)) } || {}
        );
      },
      providesTags: ['Devices']
    }),
    getStreamingDevicesBulk: builder.query<
      ApiListResponse<StreamingDevice>,
      ApiListRequestParams & { organizationIds: string[] }
    >({
      query: ({ organizationIds, ...rest }) => {
        const orgIdQuery = organizationIds.map((id) => `organization_id:${id}`).join(',');

        return buildQueryParams(`/search/bulk`, { q: orgIdQuery, ...rest } || {});
      },
      providesTags: ['Devices']
    }),
    editStreamingDevice: builder.mutation<
      StreamingDevice,
      Partial<StreamingDevice> & { shouldInvalidate?: boolean }
    >({
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      query: ({ id, shouldInvalidate, ...body }) => ({
        url: `/${id}`,
        method: 'PUT',
        body
      }),
      invalidatesTags: (result, error, { shouldInvalidate = true }) => {
        if (shouldInvalidate) {
          return ['Devices', 'Device'];
        }

        return ['Device'];
      }
    }),
    createStreamingDevice: builder.mutation<StreamingDevice, Partial<StreamingDevice>>({
      query: (device) => ({
        url: '/',
        method: 'POST',
        body: device
      }),
      invalidatesTags: ['Devices', 'Device']
    }),
    deleteStreamingDevice: builder.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: `/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Devices', 'Device']
    })
  })
});

export const {
  useStreamingDevicesQuery,
  useGetStreamingDeviceQuery,
  useLazyGetStreamingDeviceQuery,
  useGetStreamingDevicesByOrganizationIdQuery,
  useLazyGetStreamingDevicesBulkQuery,
  useEditStreamingDeviceMutation,
  useDeleteStreamingDeviceMutation,
  useCreateStreamingDeviceMutation
} = streamingDevicesApi;
