import { useSelector } from 'react-redux';
import React, { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';

import { selectCurrentUser } from '../../../store/user/selectors';
import { handleCatch } from '../../../store/services/helpers';
import { useChangePasswordMutation } from '../../../store/services/authApi';
import { wrongPasswordMessage } from '../../../store/errorHandlerMiddleware';
import isFetchBaseQueryError from '../../../helpers/isFetchBaseQueryError';
import InputField from '../../../components/Input';
import Criteria from '../../../components/Criteria';
import Button from '../../../components/Button';

const PasswordChange: FC<{ onChangePassword: () => void }> = ({ onChangePassword }) => {
  const user = useSelector(selectCurrentUser);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [changePassword, { isLoading, error: errorChange }] = useChangePasswordMutation();
  const [error, setError] = useState('');

  const [criteria, setCriteria] = useState({
    minLength: false,
    uppercase: false,
    lowercase: false,
    specialChar: false,
    number: false
  });

  const areAllCriteriaMet = useMemo(
    () => Object.values(criteria).every((isMet) => isMet),
    [criteria]
  );

  useEffect(() => {
    if (isFetchBaseQueryError(errorChange)) {
      setError(errorChange?.data?.message);
    }
  }, [errorChange]);

  useEffect(() => {
    setCriteria({
      minLength: password.length >= 8,
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      specialChar: /[@$!%*?&]/.test(password),
      number: /\d/.test(password)
    });
  }, [password]);

  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newPassword = e.target.value;
    setPassword(newPassword);

    if (newPassword !== confirmPassword && confirmPassword.length) {
      setConfirmPasswordError('Passwords must match');
    } else {
      setConfirmPasswordError('');
    }
  };

  const handleConfirmPassword = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setConfirmPassword(value);

    if (value !== password) {
      setConfirmPasswordError('Passwords must match');
    } else {
      setConfirmPasswordError('');
    }
  };

  const handleCurrentPassword = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setCurrentPassword(value);
  };

  const resetForm = () => {
    setPassword('');
    setConfirmPassword('');
    setError('');
    setConfirmPasswordError('');
    setCurrentPassword('');
  };

  const handleSubmit = async () => {
    if (user) {
      try {
        await changePassword({
          username: user.username,
          oldPassword: currentPassword,
          newPassword: password
        }).unwrap();
        onChangePassword();
        resetForm();
      } catch (e) {
        handleCatch(e);

        if (isFetchBaseQueryError(e)) {
          if (e.data.message === wrongPasswordMessage) {
            setError('Current password is wrong. Please try again.');
          }
        }
      }
    }
  };

  const handleCancel = () => {
    resetForm();
  };

  const hasChanges = useMemo(
    () => password.length > 0 || confirmPassword.length > 0 || currentPassword.length > 0,
    [password, confirmPassword, currentPassword]
  );

  return (
    <div className="section">
      <h5 className="profile-section-title">Change Password</h5>

      <div className="profile-inputs-container align-top">
        <div className="profile-pass-and-reqs-container">
          <InputField
            type="password"
            value={password}
            onChange={handlePasswordChange}
            id="password"
            label="Password (8-20 Characters) *"
            isPassword
            showErrorBlock
            success={areAllCriteriaMet}
          />
          <ul className="profile-requirements">
            <Criteria isCriteriaMet={criteria.minLength} message="Minimum 8 characters" />
            <Criteria
              isCriteriaMet={criteria.uppercase}
              message="Includes an uppercase character"
            />
            <Criteria isCriteriaMet={criteria.lowercase} message="Includes a lowercase character" />
            <Criteria isCriteriaMet={criteria.specialChar} message="Includes a special character" />
            <Criteria isCriteriaMet={criteria.number} message="Includes a number" />
          </ul>
        </div>

        <InputField
          type="password"
          value={confirmPassword}
          onChange={handleConfirmPassword}
          id="confirm-password"
          label="Confirm Password *"
          isPassword
          error={confirmPasswordError}
          showErrorBlock
          success={password === confirmPassword && !!confirmPassword.length}
        />

        <InputField
          type="password"
          value={currentPassword}
          onChange={handleCurrentPassword}
          id="current-password"
          label="Current Password *"
          isPassword
          showErrorBlock
        />
      </div>
      <div className="error-message">{error}</div>

      <div className="profile-buttons-container">
        <Button
          variant="primary"
          disabled={
            isLoading ||
            !password.length ||
            !confirmPassword.length ||
            !currentPassword.length ||
            !areAllCriteriaMet
          }
          onClick={handleSubmit}
        >
          Change Password
        </Button>
        <Button variant="secondary" onClick={handleCancel} disabled={!hasChanges}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default PasswordChange;
