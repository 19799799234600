import { Link } from 'react-router-dom';
import { ColumnsType, ColumnType } from 'rc-table/lib/interface';
import { AlignType } from 'rc-table/es/interface';
import { capitalize } from 'lodash';

import { Event } from '../../types/events';
import ROUTES from '../../routes.constants';
import TableActions from '../../components/CNCTable/components/TableActions';
import SortableColumn from '../../components/CNCTable/components/SortableColumn';
import Button from '../../components/Button';
import { ReactComponent as DeleteIcon } from '../../assets/delete-icon.svg';

type EventColumnType = ColumnType<Event> & { align?: AlignType };

type EventsColumnsParams = {
  onStopEvent: (id: string) => void;
  onDeleteEvent: (id: string) => void;
  isVar: boolean;
};

export const eventsColumns = ({
  onStopEvent,
  onDeleteEvent,
  isVar
}: EventsColumnsParams): ColumnsType<Event> => [
  {
    title: <SortableColumn<Event> label="Name" sortKey="name" />,
    render: (record) => (
      <Link to={ROUTES.EVENT_EDIT.replace(':id', record.id)} className="table-cell-link">
        {record.name}
      </Link>
    ),
    align: 'left',
    width: 200
  },
  {
    title: <SortableColumn<Event> label="Duration" sortKey="duration" />,
    render: (duration) => (duration > 71 ? `${Math.ceil(duration / 24)} days` : `${duration} hs`),
    dataIndex: 'duration',
    align: 'center',
    width: 110
  },
  {
    title: <SortableColumn<Event> label="Start Date" sortKey="start_time" />,
    render: (start_time) => (start_time ? new Date(start_time).toLocaleDateString() : '-'),
    dataIndex: 'start_time',
    align: 'center',
    width: 120
  },
  {
    title: <SortableColumn<Event> label="State" sortKey="state" />,
    render: (state) => capitalize(state),
    dataIndex: 'state',
    align: 'left',
    width: 120
  },
  ...(isVar
    ? [
        {
          title: <SortableColumn<Event> label="Type" sortKey="type" />,
          render: (type) => (type === 'MULTIPLE' ? 'Multi Organization' : 'Regular Event'),
          dataIndex: 'type',
          align: 'left',
          width: 140
        } as EventColumnType
      ]
    : []),
  {
    title: 'Actions',
    align: 'center',
    width: 260,
    render: ({ id, state }) => (
      <>
        <TableActions id={id} editUrl={ROUTES.EVENT_EDIT.replace(':id', id)}>
          <Button
            className="stop-button"
            variant="link"
            disabled={state === 'finished'}
            onClick={() => state !== 'finished' && onStopEvent(id)}
          >
            Stop event
          </Button>
          <Button
            className="delete-button"
            variant="link"
            disabled={state === 'active'}
            onClick={() => state !== 'active' && onDeleteEvent(id)}
          >
            Delete event <DeleteIcon />
          </Button>
        </TableActions>
      </>
    )
  }
];
