import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { Event, EventState } from '../../types/events';
import { ApiListRequestParams, ApiListResponse } from '../../types/api';
import config from '../../config';

import { buildQueryParams } from './helpers';

export const eventsApi = createApi({
  reducerPath: 'eventsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${config.apiUrl}/events`,
    credentials: 'include'
  }),
  tagTypes: ['Event', 'Events'],

  endpoints: (builder) => ({
    events: builder.query<
      ApiListResponse<Event>,
      ApiListRequestParams & { state?: EventState | 'all' }
    >({
      query: (params) => buildQueryParams(`/search`, params),
      providesTags: ['Events']
    }),
    getEventById: builder.query<Event, { id: string }>({
      query: ({ id }) => `/${id}`,
      providesTags: ['Event']
    }),
    createEvent: builder.mutation<Event, Partial<Event>>({
      query: (event) => ({
        url: '/',
        method: 'POST',
        body: event
      }),
      invalidatesTags: ['Event', 'Events']
    }),
    editEvent: builder.mutation<Event, Partial<Event>>({
      query: ({ id, ...body }) => ({
        url: `/${id}`,
        method: 'PUT',
        body
      }),
      invalidatesTags: ['Event', 'Events']
    }),
    stopEvent: builder.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: `/${id}/stop`,
        method: 'PUT'
      }),
      invalidatesTags: ['Event', 'Events']
    }),
    deleteEvent: builder.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: `/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Events']
    })
  })
});

export const {
  useEventsQuery,
  useEditEventMutation,
  useCreateEventMutation,
  useStopEventMutation,
  useGetEventByIdQuery,
  useDeleteEventMutation
} = eventsApi;
