import React, {
  ButtonHTMLAttributes,
  ForwardRefRenderFunction,
  ReactNode,
  forwardRef
} from 'react';

import './Button.scss';

export type ButtonVariant = 'primary' | 'secondary' | 'delete' | 'link';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: ButtonVariant;
  children: ReactNode;
  className?: string;
  suffix?: ReactNode;
}

const ButtonComponent: ForwardRefRenderFunction<HTMLButtonElement, ButtonProps> = (
  { children, className = '', variant = 'primary', suffix, ...props },
  ref
) => (
  <button className={`button ${variant} ${className}`.trim()} ref={ref} {...props}>
    {children}
    {suffix && <span className="suffix">{suffix}</span>}
  </button>
);

ButtonComponent.displayName = 'Button';

const Button = forwardRef(ButtonComponent);

export default Button;
