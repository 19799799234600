import { useNavigate, useParams } from 'react-router-dom';
import React, { FC, KeyboardEvent, useState } from 'react';

import { useLazyAuthSpecialEventQuery } from '../../../store/services/specialEventsApi';
import ROUTES from '../../../routes.constants';
import isFetchBaseQueryError from '../../../helpers/isFetchBaseQueryError';
import Loader from '../../../components/Loader';
import InputField from '../../../components/Input';
import Button from '../../../components/Button';
import logo from '../../../assets/logo.png';

import './LoginSpecialEventForm.scss';

const LoginSpecialEventForm: FC = () => {
  const navigate = useNavigate();
  const { eventKey } = useParams();
  const [email, setEmail] = useState('');
  const [authenticateUser, { isLoading, isError, error }] = useLazyAuthSpecialEventQuery();

  const handleSubmit = async () => {
    try {
      if (eventKey) {
        await authenticateUser({ email, eventKey }).unwrap();
        localStorage.setItem('email', email);
        navigate(ROUTES.SPECIAL_EVENT.replace(':eventKey', eventKey));
      }
    } catch (err) {
      if (isFetchBaseQueryError(err)) {
        if (eventKey) {
          navigate(ROUTES.EVENT_LOGIN.replace(':eventKey', eventKey));
        } else {
          navigate(ROUTES.LOGIN);
        }
      }
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <section className="login-special-event-form">
      <img src={logo} alt="logo" className="login-special-event-form-logo" />
      {isLoading && <Loader />}
      <h2>Event Access</h2>
      <h5>Enter a valid Email Address</h5>

      <InputField
        className="login-special-event-form-email-input"
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        onKeyDown={handleKeyDown}
        id="email"
        label="Email"
      />

      <div
        className={`login-special-event-form-error-message ${
          isError ? 'login-special-event-form-visible' : ''
        }`}
      >
        {isFetchBaseQueryError(error) ? error.data.message : 'Invalid Email'}
      </div>

      <Button
        disabled={isLoading || !email}
        onClick={handleSubmit}
        className="login-special-event-form-submit-button"
      >
        Enter
      </Button>
    </section>
  );
};

export default LoginSpecialEventForm;
