import React, { FC, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import { validateDomain } from '../../helpers/validators';
import { ReactComponent as DeleteIcon } from '../../assets/delete-icon.svg';

import './DomainPillInput.scss';

interface DomainPillInputProps {
  label: string;
  domains?: string[];
  onChange: (domains: string[]) => void;
}

interface EditableDomain {
  index: number;
  value: string;
}

const DomainPillInput: FC<DomainPillInputProps> = ({ label, domains, onChange }) => {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [editableDomain, setEditableDomain] = useState<EditableDomain | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (domains) {
      onChange(domains);
    }
  }, [domains]);

  const enterEditMode = (index: number) => {
    if (domains) {
      setEditableDomain({ index, value: domains[index] });
    }
  };

  const exitEditMode = () => {
    setEditableDomain(null);

    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  const updateDomain = (newDomain: string, index: number) => {
    if (domains) {
      onChange(domains.map((email, i) => (i === index ? newDomain : email)));
    }
  };

  const removeDomain = (index: number) => {
    if (domains) {
      onChange(domains.filter((_, i) => i !== index));
    }

    if (editableDomain && editableDomain.index === index) {
      exitEditMode();
    }
  };

  const handleSubmitDomain = () => {
    if (editableDomain?.value || inputRef.current?.value) {
      const newDomain = editableDomain
        ? editableDomain.value.trim()
        : inputRef.current?.value.trim();
      const error = validateDomain(newDomain as string);
      setErrorMessage(error);

      if (!error) {
        if (newDomain && !domains?.includes(newDomain)) {
          if (editableDomain) {
            updateDomain(newDomain, editableDomain.index);
            setEditableDomain(null);
          } else {
            onChange([newDomain, ...(domains ?? [])]);

            if (inputRef.current) {
              inputRef.current.value = '';
            }
          }
        }

        setErrorMessage('');
      }
    } else {
      exitEditMode();
      setErrorMessage('');
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSubmitDomain();
    }
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();
    const pastedText = event.clipboardData.getData('text');
    const newDomains = pastedText
      .split(/[\s,]+/)
      .filter((domain) => validateDomain(domain) === '' && !domains?.includes(domain));

    onChange([...newDomains, ...(domains ?? [])]);
  };

  return (
    <div className="domain-pill-input">
      <div className="label-container">
        <label>{label}</label>
      </div>

      <div className="pills-container" onClick={() => inputRef.current?.focus()}>
        <div className="add-email-container">
          {editableDomain === null && (
            <>
              <input
                className="add-email-input"
                type="text"
                onKeyDown={handleKeyDown}
                onPaste={handlePaste}
                ref={inputRef}
                placeholder="Add domain and press enter"
                onBlur={handleSubmitDomain}
              />
              <span className="domain-pill-input-confirm" onClick={handleSubmitDomain}>
                ADD
              </span>
            </>
          )}
        </div>

        {!!domains?.length && <div className="separator" />}

        {domains?.map((domain, index) =>
          editableDomain && editableDomain.index === index ? (
            <div key={index} className="pill-edit-mode">
              <input
                type="text"
                value={editableDomain.value}
                onChange={(e) => setEditableDomain({ ...editableDomain, value: e.target.value })}
                onKeyDown={handleKeyDown}
                autoFocus
              />
              <span
                className="domain-pill-input-confirm"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleSubmitDomain();
                }}
              >
                Save
              </span>
              <span
                className="domain-pill-input-confirm"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  exitEditMode();
                  setErrorMessage('');
                }}
              >
                Cancel
              </span>
            </div>
          ) : (
            <div key={index} className="pill">
              {domain}
              <button type="button" onClick={() => enterEditMode(index)}>
                &#9998;
              </button>
              <button type="button" onClick={() => removeDomain(index)}>
                <DeleteIcon />
              </button>
            </div>
          )
        )}
      </div>
      <div className={classNames('error-message')}>{errorMessage}</div>
    </div>
  );
};

export default DomainPillInput;
