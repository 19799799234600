import React, { FC } from 'react';
import classNames from 'classnames';

import './Criteria.scss';

interface IconProps {
  isCriteriaMet: boolean;
  message: string;
  white?: boolean;
}

const Criteria: FC<IconProps> = ({ isCriteriaMet, message, white = false }) => (
  <li className={classNames('criteria', { ['criteria-white']: white })}>
    <span
      className={classNames({
        'criteria-passed': isCriteriaMet,
        'criteria-failed': !isCriteriaMet
      })}
    >
      {isCriteriaMet ? '\u2714' : '\u274C'}
    </span>
    {message}
  </li>
);

export default Criteria;
