import React, { FC } from 'react';

import Checkbox from '../../../components/Checkbox/Checkbox';

import './CheckboxGroup.scss';

export type CheckboxType = 'inApp' | 'email' | 'viewable';

interface CheckboxGroupProps {
  inAppNotifications: boolean;
  emailNotifications: boolean;
  viewable: boolean;
  disabled: boolean;
  handleCheckboxChange: (type: CheckboxType, checked: boolean) => void;
}

const CheckboxGroup: FC<CheckboxGroupProps> = ({
  handleCheckboxChange,
  inAppNotifications,
  emailNotifications,
  viewable,
  disabled
}) => (
  <div className="checkbox-group">
    <div className="checkbox-group-container">
      <Checkbox
        label="In-app Notifications"
        checked={inAppNotifications}
        onChange={(checked) => handleCheckboxChange('inApp', checked)}
      />

      <Checkbox
        label="Email Notifications"
        checked={emailNotifications}
        onChange={(checked) => handleCheckboxChange('email', checked)}
      />

      <Checkbox
        disabled={disabled}
        label="Viewable"
        checked={viewable}
        onChange={(checked) => handleCheckboxChange('viewable', checked)}
      />
    </div>
  </div>
);

export default CheckboxGroup;
