import { Link } from 'react-router-dom';
import React, { FC, ReactNode } from 'react';

import Button from '../../../Button';
import { ReactComponent as PencilIcon } from '../../../../assets/pencil-icon.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/delete-icon.svg';

import './TableActions.scss';

type Props = {
  id: string;
  editUrl?: string;
  onDelete?: () => void;
  onActivate?: () => void;
  onDeactivate?: () => void;
  children?: ReactNode;
};

const TableActions: FC<Props> = ({ children, editUrl, onDelete, onActivate, onDeactivate }) => {
  const handleDelete = () => {
    if (onDelete) onDelete();
  };

  const handleActivate = () => {
    if (onActivate) onActivate();
  };

  const handleDeactivate = () => {
    if (onDeactivate) onDeactivate();
  };

  return (
    <div className="table-actions">
      {editUrl && (
        <Link to={editUrl}>
          <Button variant="link" className="edit-button" suffix={<PencilIcon />}>
            Edit
          </Button>
        </Link>
      )}

      {onDeactivate && (
        <Button variant="link" className="deactivate-button" onClick={handleDeactivate}>
          Deactivate
        </Button>
      )}

      {onActivate && (
        <Button variant="link" className="edit-button" onClick={handleActivate}>
          Reactivate
        </Button>
      )}

      {onDelete && (
        <Button
          variant="link"
          className="delete-button"
          suffix={<DeleteIcon />}
          onClick={handleDelete}
        >
          Delete
        </Button>
      )}

      {children}
    </div>
  );
};

export default TableActions;
