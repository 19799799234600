import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import React, { KeyboardEventHandler, useEffect, useRef, useState } from 'react';

import OrganizationForm from '../OrganizationForm';
import { ROLES } from '../../types/users';
import { Organization } from '../../types/organizations';
import { selectCurrentUser } from '../../store/user/selectors';
import { useOrganizationsQuery } from '../../store/services/organizationsApi';
import ROUTES from '../../routes.constants';
import useDocumentTitle from '../../helpers/useTitle';
import debounceValidation from '../../helpers/debounceValidation';
import InputField from '../../components/Input';
import { Sort } from '../../components/CNCTable/helpers';
import CNCTable from '../../components/CNCTable';
import Button from '../../components/Button';
import { ReactComponent as SearchIcon } from '../../assets/search-icon.svg';

import { organizationColumns } from './Organizations.table';

import './Organizations.scss';

const PAGE_SIZE = 10;

const Organizations = () => {
  const user = useSelector(selectCurrentUser);
  const navigate = useNavigate();
  useDocumentTitle('Organizations');

  const organizationsRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const [searchText, setSearchText] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [sortField, setSortField] = useState<string | undefined>();
  const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC' | undefined>();

  const sortingQueryParam =
    sortField && sortDirection ? `${sortField}:${sortDirection}` : undefined;

  const { data, isFetching } = useOrganizationsQuery(
    {
      ...(searchText && { q: `name:${searchText}` }),
      page_size: PAGE_SIZE,
      page: currentPage,
      sort: sortingQueryParam
    },
    { refetchOnMountOrArgChange: true }
  );

  useEffect(() => {
    const handleScroll = () => {
      if (!organizationsRef.current || !buttonRef.current) return;

      const { bottom } = organizationsRef.current.getBoundingClientRect();
      const buttonHeight = buttonRef.current.offsetHeight;

      const offsetBottom = 20;

      if (bottom <= window.innerHeight - offsetBottom - buttonHeight) {
        buttonRef.current.style.position = 'absolute';
        buttonRef.current.style.bottom = '3rem';
      } else {
        buttonRef.current.style.position = 'fixed';
        buttonRef.current.style.bottom = '2rem';
      }
    };

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleSearchInput: KeyboardEventHandler<HTMLInputElement> = (event) => {
    const { value = '' } = event.target as never;

    if (!value.length || value.length >= 3) {
      debounceValidation(() => {
        setSearchText(value);
        setCurrentPage(0);
      });
    }
  };

  const handleSortChange = (sort: Sort<Organization>) => {
    setSortField(sort.key);
    setSortDirection(sort.dir);
    setCurrentPage(0);
  };

  return user?.role === ROLES.VAR ? (
    <div className="organizations" ref={organizationsRef}>
      <h1>Organizations</h1>

      <div className="organizations-search-container">
        <InputField
          id="search-text"
          className="organizations-search-input"
          type="text"
          icon={<SearchIcon />}
          placeholder="Search"
          label=""
          onKeyUp={handleSearchInput}
        />
      </div>

      <CNCTable<Organization>
        key={searchText}
        className="organizations-table"
        columns={organizationColumns}
        data={data}
        loading={isFetching}
        pageSize={PAGE_SIZE}
        onPageChange={handlePageChange}
        onSort={handleSortChange}
      />

      <Button
        ref={buttonRef}
        className="organizations-create-button"
        variant="primary"
        onClick={() => navigate(ROUTES.ORGANIZATION_CREATE)}
      >
        Create
      </Button>
    </div>
  ) : (
    <OrganizationForm myOrganizationId={user?.organization?.id} />
  );
};

export default Organizations;
