import React, { FC } from 'react';

import { useTableContext } from '../../providers/CNCTable.context';
import { TableRecord, TableSelection } from '../../helpers';
import RadioInput from '../../../RadioInput';
import Checkbox from '../../../Checkbox/Checkbox';

type Props = {
  selectionType: TableSelection;
  record: TableRecord;
};

const RowSelector: FC<Props> = ({ record, selectionType }) => {
  const { selection, setSelection } = useTableContext<TableRecord>();

  const isSingle = selectionType === 'single';
  const isSelected = selection.some((item) => item.id === record.id);

  const handleSingleSelect = () => {
    setSelection([record]);
  };

  const handleMultiSelect = (checked: boolean) => {
    if (checked) {
      setSelection([...selection, record]);
    } else {
      setSelection(selection.filter((item) => item.id !== record.id));
    }
  };

  if (isSingle) {
    return (
      <RadioInput
        name="row-select"
        checked={isSelected}
        onCheckedChange={handleSingleSelect}
        readOnly
      />
    );
  }

  return <Checkbox checked={isSelected} onChange={handleMultiSelect} centered />;
};

export default RowSelector;
