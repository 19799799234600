import DatePicker, { ReactDatePicker } from 'react-datepicker';
import React, { FC, useRef } from 'react';

import { ReactComponent as CalendarIcon } from '../../assets/calendar-icon.svg';

import './DateTimePicker.scss';

type DateTimePickerProps = {
  startDate: Date | undefined;
  onSelectDate: (date: Date) => void;
  disabled: boolean;
};

const DateTimePicker: FC<DateTimePickerProps> = ({ startDate, onSelectDate, disabled }) => {
  const datePickerRef = useRef<ReactDatePicker>(null);
  const filterPassedTime = (time: Date) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };

  const handleIconClick = () => {
    if (datePickerRef.current) {
      datePickerRef.current.setOpen(true);
    }
  };

  return (
    <div className="date-container">
      <DatePicker
        ref={datePickerRef}
        placeholderText="Select date"
        minDate={new Date()}
        selected={startDate}
        showTimeSelect
        disabled={disabled}
        onChange={onSelectDate}
        dateFormat="Pp"
        filterTime={filterPassedTime}
        timeIntervals={15}
      />
      <CalendarIcon onClick={handleIconClick} />
    </div>
  );
};

export default DateTimePicker;
