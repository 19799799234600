import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import React, { FC } from 'react';

import { ROLES } from '../../types/users';
import { selectCurrentUser } from '../../store/user/selectors';
import ROUTES from '../../routes.constants';

import './AdminNav.scss';

const AdminNav: FC = () => {
  const user = useSelector(selectCurrentUser);
  const getNavLinkClass = ({ isActive }: { isActive: boolean }) =>
    `admin-nav-tab ${isActive ? 'admin-nav-active' : ''}`;

  return (
    <div className="admin-nav">
      <NavLink to={ROUTES.ORGANIZATIONS} className={getNavLinkClass}>
        {`Organization${user?.role === ROLES.VAR ? 's' : ''}`}
      </NavLink>
      <NavLink to={ROUTES.STREAMING_DEVICES} className={getNavLinkClass}>
        Streaming Devices
      </NavLink>
      <NavLink to={ROUTES.EVENTS} className={getNavLinkClass}>
        Events
      </NavLink>
      <NavLink to={ROUTES.USERS} className={getNavLinkClass}>
        Users
      </NavLink>
    </div>
  );
};

export default AdminNav;
