import React from 'react';

import { ButtonVariant } from '../Button/Button';
import Button from '../Button';

import './ConfirmModal.scss';

interface ConfirmModalProps {
  isOpen: boolean;
  onClose?: () => void;
  onConfirm: () => void;
  message: string;
  cancelText?: string;
  confirmText?: string;
  variant?: ButtonVariant;
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  message,
  confirmText = 'Confirm',
  cancelText = 'Cancel',
  variant = 'delete'
}) => {
  if (!isOpen) return null;

  return (
    <div className="confirm-modal">
      <div className="confirm-modal-content">
        <p>{message}</p>
        <div className="confirm-modal-actions">
          {cancelText && onClose && (
            <Button variant="secondary" onClick={onClose}>
              {cancelText}
            </Button>
          )}
          {confirmText && (
            <Button variant={variant} onClick={onConfirm}>
              {confirmText}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ConfirmModal;
