import React, { FC } from 'react';

import useTitle from '../../helpers/useTitle';
import AuthLayout from '../../components/AuthLayout';

import LoginSpecialEventForm from './LoginSpecialEventForm';

const LoginSpecialEvent: FC = () => {
  useTitle('Login Event');

  return (
    <AuthLayout isSpecialEventLogin>
      <LoginSpecialEventForm />
    </AuthLayout>
  );
};

export default LoginSpecialEvent;
