import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';

import { ROLES, User } from '../../types/users';
import { useEditProfileMutation, useProfileQuery } from '../../store/services/usersApi';
import { useGetOrganizationByIdQuery } from '../../store/services/organizationsApi';
import { handleCatch } from '../../store/services/helpers';
import { validateNumber } from '../../helpers/validators';
import useTitle from '../../helpers/useTitle';
import Select from '../../components/Select';
import { NotificationType } from '../../components/NotificationsCheckboxGroup/NotificationsCheckboxGroup';
import NotificationsCheckboxGroup from '../../components/NotificationsCheckboxGroup';
import Loader from '../../components/Loader';
import InputField from '../../components/Input';
import CoverImage from '../../components/CoverImage';
import ConfirmModal from '../../components/ConfirmModal';
import Button from '../../components/Button';
import profile from '../../assets/profile-background.jpg';

import PasswordChange from './PasswordChange';

import './Profile.scss';

const Profile = () => {
  const { data: user, isLoading: isLoadingProfile } = useProfileQuery(undefined, {
    refetchOnMountOrArgChange: true
  });
  const [editProfile, { isLoading: isLoadingEdit }] = useEditProfileMutation();
  const { data: organization, isLoading: isLoadingOrganization } = useGetOrganizationByIdQuery(
    { id: user?.organization?.id },
    { skip: !user?.organization?.id }
  );
  const isLoading = useMemo(
    () => isLoadingProfile || isLoadingEdit || isLoadingOrganization,
    [isLoadingProfile, isLoadingEdit, isLoadingOrganization]
  );
  const [userInfo, setUserInfo] = useState({
    name: '',
    username: '',
    position_title: '',
    phone_number: '',
    role: ''
  });
  const [inAppNotifications, setInAppNotifications] = useState(false);
  const [emailNotifications, setEmailNotifications] = useState(false);
  const [errors, setErrors] = useState({
    phone_number: ''
  });
  const [isModalOpen, setIsModalOpen] = useState(false);

  useTitle(user?.name || 'Profile');

  useEffect(() => {
    if (user) {
      setUserInfo({
        name: user.name,
        username: user.username,
        position_title: user.position_title,
        phone_number: user.phone_number.replace('+1', ''),
        role: user.role
      });
      setInAppNotifications(user.in_app_notifications);
      setEmailNotifications(user.email_notifications);
    }
  }, [user]);

  const validateField = (fieldId: string, value: string) => {
    switch (fieldId) {
      case 'phone_number':
        return validateNumber(value);
      default:
        return '';
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setUserInfo((prev) => ({ ...prev, [id]: value }));

    if (id in errors) {
      setErrors((prev) => ({ ...prev, [id]: validateField(id, value) }));
    }
  };

  const handleSubmit = async () => {
    const payload: Partial<User> = {};

    if (user?.position_title !== userInfo.position_title) {
      payload.position_title = userInfo.position_title;
    }

    if (user?.phone_number !== `+1${userInfo.phone_number}`) {
      payload.phone_number = `+1${userInfo.phone_number}`;
    }

    if (user?.email_notifications !== emailNotifications) {
      payload.email_notifications = emailNotifications;
    }

    if (user?.in_app_notifications !== inAppNotifications) {
      payload.in_app_notifications = inAppNotifications;
    }

    try {
      await editProfile(payload).unwrap();
      setIsModalOpen(true);
    } catch (e) {
      handleCatch(e);
    }
  };

  const handleBlur = (fieldId: keyof typeof userInfo) => {
    setErrors((prev) => ({ ...prev, [fieldId]: validateField(fieldId, userInfo[fieldId]) }));
  };

  const handleCancel = () => {
    if (user) {
      setUserInfo({
        ...userInfo,
        position_title: user.position_title,
        phone_number: user.phone_number.replace('+1', '')
      });
      setInAppNotifications(user.in_app_notifications);
      setEmailNotifications(user.email_notifications);

      setErrors({
        phone_number: ''
      });
    }
  };

  const handleNotificationChange = (type: NotificationType, value: boolean) => {
    if (type === 'inApp') {
      setInAppNotifications(value);
    } else if (type === 'email') {
      setEmailNotifications(value);
    }
  };

  const hasChanges = useMemo(
    () =>
      user &&
      (user.position_title !== userInfo.position_title ||
        user.phone_number !== `+1${userInfo.phone_number}` ||
        user.email_notifications !== emailNotifications ||
        user.in_app_notifications !== inAppNotifications),
    [user, userInfo, inAppNotifications, emailNotifications]
  );

  const hasErrors = useMemo(() => Object.values(errors).some((error) => error !== ''), [errors]);

  return (
    <div>
      {isLoading && <Loader />}
      <CoverImage backgroundImage={profile} />
      <div className="profile">
        <div className="profile-title-container">
          <h1 className="profile-title">Profile Information</h1>
        </div>

        <div className="profile-inputs-wrapper">
          <div className="section">
            <h5 className="profile-section-title">Information</h5>

            <div className="profile-inputs-container">
              <InputField
                type="text"
                disabled
                value={userInfo.name}
                onChange={handleInputChange}
                id="name"
                label="Name"
                showErrorBlock
              />

              {user?.role !== ROLES.VAR && (
                <InputField
                  type="text"
                  disabled
                  value={organization?.name || ''}
                  id="organization"
                  label="Organization"
                  showErrorBlock
                />
              )}

              <InputField
                type="email"
                disabled
                value={user?.username}
                id="username"
                label="Email Address"
                showErrorBlock
              />

              <InputField
                type="text"
                value={userInfo.position_title}
                onChange={handleInputChange}
                id="position_title"
                label="Position/Title"
                showErrorBlock
              />

              <InputField
                type="text"
                value={userInfo.phone_number}
                onChange={handleInputChange}
                id="phone_number"
                label="Phone Number"
                error={errors.phone_number}
                showErrorBlock
                onBlur={() => handleBlur('phone_number')}
              />

              <Select
                disabled
                label="Role"
                value={userInfo.role}
                onChange={() => {} /*does nothing*/}
                id="select-role"
                options={[
                  { value: ROLES.VAR, label: 'VAR' },
                  { value: ROLES.LOCAL_ADMIN, label: 'Local Admin' },
                  { value: ROLES.VIEWER, label: 'Viewer' }
                ]}
              />

              <NotificationsCheckboxGroup
                inAppNotifications={inAppNotifications}
                emailNotifications={emailNotifications}
                handleNotificationChange={handleNotificationChange}
              />
            </div>

            <div className="profile-buttons-container">
              <Button
                variant="primary"
                onClick={handleSubmit}
                disabled={!hasChanges || isLoading || hasErrors}
              >
                Save Changes
              </Button>
              <Button
                variant="secondary"
                onClick={handleCancel}
                disabled={!hasChanges || isLoading || hasErrors}
              >
                Cancel
              </Button>
            </div>
          </div>

          <PasswordChange onChangePassword={() => setIsModalOpen(true)} />
        </div>
      </div>
      <ConfirmModal
        isOpen={isModalOpen}
        onConfirm={() => setIsModalOpen(false)}
        message="Update successful"
        confirmText="OK"
        variant="primary"
      />
    </div>
  );
};

export default Profile;
