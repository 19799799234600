import { configureStore } from '@reduxjs/toolkit';

import userReducer from './user/slice';
import { usersApi } from './services/usersApi';
import { streamingDevicesApi } from './services/streamingDevicesApi';
import { specialEventsApi } from './services/specialEventsApi';
import { organizationsApi } from './services/organizationsApi';
import { eventsApi } from './services/eventsApi';
import { authApi } from './services/authApi';
import errorHandlerMiddleware from './errorHandlerMiddleware';

export const store = configureStore({
  reducer: {
    user: userReducer,
    [streamingDevicesApi.reducerPath]: streamingDevicesApi.reducer,
    [organizationsApi.reducerPath]: organizationsApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [eventsApi.reducerPath]: eventsApi.reducer,
    [specialEventsApi.reducerPath]: specialEventsApi.reducer,
    [authApi.reducerPath]: authApi.reducer // APIs always need to be included as reducers
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(authApi.middleware)
      .concat(streamingDevicesApi.middleware)
      .concat(eventsApi.middleware)
      .concat(organizationsApi.middleware)
      .concat(usersApi.middleware)
      .concat(specialEventsApi.middleware)
      .concat(errorHandlerMiddleware) // APIs always need their middleware to be concated
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
