import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import React, { FC, useEffect } from 'react';

import Loader from '../../Loader';
import Header from '../../Header';
import Footer from '../../Footer';
import { useLazyAuthSpecialEventQuery } from '../../../store/services/specialEventsApi';
import ROUTES from '../../../routes.constants';

const SpecialEventWrapper: FC = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const email = localStorage.getItem('email');
  const { eventKey } = useParams();
  const [authenticateUser, { isLoading }] = useLazyAuthSpecialEventQuery();

  useEffect(() => {
    const authGuest = async () => {
      try {
        await authenticateUser({ email, eventKey }).unwrap();
      } catch (e) {
        if (eventKey) {
          navigate(ROUTES.EVENT_LOGIN.replace(':eventKey', eventKey));
        } else {
          navigate(ROUTES.LOGIN);
        }
      }
    };

    authGuest();
  }, [email, eventKey]);

  return (
    <div className="app-layout">
      {!pathname.includes('login') && <Header isSpecialEvent showMenu={false} />}
      {isLoading ? (
        <Loader />
      ) : (
        <div className="app-body">
          <Outlet />
        </div>
      )}
      {!pathname.includes('login') && <Footer isSpecialEvent />}
    </div>
  );
};

export default SpecialEventWrapper;
