import { ColumnsType } from 'rc-table/lib/interface';

import { StreamingDevice } from '../../types/streamingDevices';
import SortableColumn from '../../components/CNCTable/components/SortableColumn';

export const devicesColumns: ColumnsType<StreamingDevice> = [
  {
    title: <SortableColumn<StreamingDevice> label="Stream" sortKey="name" />,
    dataIndex: 'name',
    align: 'left',
    width: 300
  }
];
