import { Middleware } from 'redux';

import { setIsLoggedIn } from './user/slice';

export const wrongPasswordMessage = 'WrongPassword error';

const errorHandlerMiddleware: Middleware = (api) => (next) => (action) => {
  if (action.error && action.payload) {
    const {
      status,
      data: { message }
    } = action.payload;

    if (status === 403 || (status === 401 && message !== wrongPasswordMessage)) {
      api.dispatch(setIsLoggedIn(false));
    }
  }

  return next(action);
};

export default errorHandlerMiddleware;
