import React, { FC } from 'react';

import { StreamingDevice } from '../../types/streamingDevices';
import defaultImage from '../../assets/live-stream-thumbnail.png';

import './VideoCard.scss';

type VideoCardProps = {
  device: StreamingDevice;
  onSelectDevice: (device: StreamingDevice) => void;
  varAdmin?: boolean;
  allOrganizationFlag?: boolean;
  eventName?: string;
};

const VideoCard: FC<VideoCardProps> = ({
  onSelectDevice,
  device,
  device: { name, description, thumbnail, activated_at, active, organization },
  varAdmin,
  allOrganizationFlag,
  eventName
}) => {
  const calculateTimeActive = (): string => {
    const startTime = new Date(activated_at);
    const currentTime = new Date();

    const diff: number = currentTime.getTime() - startTime.getTime();

    const seconds: number = Math.floor((diff / 1000) % 60);
    const minutes: number = Math.floor((diff / (1000 * 60)) % 60);
    const hours: number = Math.floor(diff / (1000 * 60 * 60));

    const formattedSeconds: string = seconds < 10 ? '0' + seconds : seconds.toString();
    const formattedMinutes: string = minutes < 10 ? '0' + minutes : minutes.toString();
    const formattedHours: string = hours < 10 ? '0' + hours : hours.toString();

    return `Active for ${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  };

  return (
    <div className="video-card">
      <div
        onClick={() => onSelectDevice(device)}
        className="video-card-thumbnail"
        style={{ backgroundImage: `url(${thumbnail || defaultImage})` }}
      />
      {varAdmin && allOrganizationFlag ? (
        <div className="video-card-info-container">
          <div className="video-card-title">{eventName}</div>
          <div className="video-card-description" title={name}>
            {name}
          </div>
          {activated_at && active && (
            <div className="video-card-time-active">{calculateTimeActive()}</div>
          )}
          <div className="video-card-others">{organization?.name}</div>
        </div>
      ) : (
        <div className="video-card-info-container">
          <div className="video-card-title">{name}</div>
          <div className="video-card-description" title={description}>
            {description}
          </div>
          {activated_at && active && (
            <div className="video-card-time-active">{calculateTimeActive()}</div>
          )}
        </div>
      )}
    </div>
  );
};

export default VideoCard;
