import React, { FC } from 'react';

import { ReactComponent as CloseIcon } from '../../../../assets/close-icon.svg';
import { ReactComponent as CheckIcon } from '../../../../assets/check-icon.svg';

type Props = { value: boolean };

const BooleanCell: FC<Props> = ({ value }) => {
  const sizes = { width: 8, height: 8 };

  if (value) return <CheckIcon {...sizes} />;

  return <CloseIcon {...sizes} />;
};

export default BooleanCell;
