import { RegionType, StreamingDeviceType, ProtocolType } from '../../types/streamingDevices';

export const deviceTypeOptions: { value: StreamingDeviceType; label: string }[] = [
  { value: 'DRONES', label: 'Drone' },
  { value: 'FIXED', label: 'Fixed' },
  { value: 'MOBILE', label: 'Mobile' }
];

export const regionOptions: { value: RegionType; label: string }[] = [
  { value: 'east-1', label: 'east' },
  { value: 'west-2', label: 'west' }
];

export const protocolTypeOptions: { value: ProtocolType; label: string }[] = [
  { value: 'rtmp', label: 'RTMP' },
  { value: 'srt', label: 'SRT' }
];
