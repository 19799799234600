import { Link, useNavigate } from 'react-router-dom';
import React, { ChangeEvent, FC, useEffect, useMemo, useRef, useState } from 'react';

import { useLearnMoreMutation } from '../../../store/services/authApi';
import ROUTES from '../../../routes.constants';
import {
  validateCompany,
  validateEmail,
  validateName,
  validateNumber
} from '../../../helpers/validators';
import isFetchBaseQueryError from '../../../helpers/isFetchBaseQueryError';
import debounceValidation from '../../../helpers/debounceValidation';
import Loader from '../../../components/Loader';
import InputField from '../../../components/Input';
import Button from '../../../components/Button';
import logo from '../../../assets/logo.png';

import './LearnMoreForm.scss';

const LearnMoreForm: FC = () => {
  const [learnMore, { isLoading, isError, error }] = useLearnMoreMutation();
  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);
  const navigate = useNavigate();
  const modalContentRef = useRef<HTMLDivElement>(null);

  // Input values
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [company, setCompany] = useState('');
  const [number, setNumber] = useState('');
  // Errors states
  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [companyError, setCompanyError] = useState('');
  const [numberError, setNumberError] = useState('');

  // Handlers
  const handleBlur =
    (validator: (value: string) => string, setter: (error: string) => void) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setter(validator(value));
    };

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newName = e.target.value;
    setName(newName);
    debounceValidation(() => setNameError(validateName(newName)));
  };

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    debounceValidation(() => setEmailError(validateEmail(newEmail)));
  };

  const handleCompanyChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newCompany = e.target.value;
    setCompany(newCompany);
    debounceValidation(() => setCompanyError(validateCompany(newCompany)));
  };

  const handleNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newNumber = e.target.value;
    setNumber(newNumber);
    // Not debouncing here to avoid submit button enabled flash
    setNumberError(validateNumber(newNumber));
  };

  const handleSubmit = async () => {
    try {
      await learnMore({ email, name, phone_number: `+1${number}`, company }).unwrap();
      setIsSuccessModalVisible(true);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  };

  const handleCloseModal = () => {
    setIsSuccessModalVisible(false);
    navigate(ROUTES.LOGIN);
  };

  const isFormValid = () =>
    !(
      nameError ||
      emailError ||
      companyError ||
      numberError ||
      !name ||
      !email ||
      !company ||
      !number
    );

  const errorMessage = useMemo(
    () => (isFetchBaseQueryError(error) ? error.data.message : 'Something went wrong'),
    [error]
  );

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        isSuccessModalVisible &&
        modalContentRef.current &&
        !modalContentRef.current.contains(event.target as Node)
      ) {
        setIsSuccessModalVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isSuccessModalVisible]);

  return (
    <section className="learn-more-form">
      <img src={logo} alt="logo" className="login-form-logo" />
      {isLoading && <Loader />}

      <header>
        <h2>Learn more about CNC Live</h2>
        <h5>Please fill out the inquiry from below and we’ll follow up with you</h5>
      </header>

      <div className="learn-more-form-input-grid">
        <InputField
          type="text"
          value={name}
          onChange={handleNameChange}
          id="name"
          label="Your Name *"
          error={nameError}
          showErrorBlock
          onBlur={handleBlur(validateName, setNameError)}
          success={!nameError && !!name.length}
        />

        <InputField
          type="email"
          value={email}
          onChange={handleEmailChange}
          id="email"
          label="Email Address *"
          error={emailError}
          showErrorBlock
          onBlur={handleBlur(validateEmail, setEmailError)}
          success={!emailError && !!email.length}
        />

        <InputField
          type="text"
          value={company}
          onChange={handleCompanyChange}
          id="company"
          label="Company *"
          error={companyError}
          showErrorBlock
          onBlur={handleBlur(validateCompany, setCompanyError)}
          success={!companyError && !!company.length}
        />

        <InputField
          type="text"
          value={number}
          onChange={handleNumberChange}
          id="number"
          placeholder="5551231234"
          label="Phone Number *"
          error={numberError}
          showErrorBlock
          onBlur={handleBlur(validateNumber, setNumberError)}
          success={!numberError && !!number.length}
        />
      </div>

      <div className="learn-more-form-actions">
        <div
          className={`learn-more-form-error-message ${isError ? 'learn-more-form-visible' : ''}`}
        >
          {errorMessage}
        </div>
        <Button
          disabled={!isFormValid() || isLoading}
          onClick={handleSubmit}
          className="learn-more-form-submit-button"
        >
          Submit
        </Button>
      </div>

      <div className="learn-more-form-log-in">
        Already have an account?{' '}
        <Link to="/login" className="link">
          Log In
        </Link>
      </div>

      {isSuccessModalVisible && (
        <div className="learn-more-form-success-modal">
          <div className="learn-more-form-modal-content" ref={modalContentRef}>
            <p>
              Thank you for contacting us.
              <br />
              We will be in touch with you soon.
            </p>
            <Button onClick={handleCloseModal}>Close</Button>
          </div>
        </div>
      )}
    </section>
  );
};

export default LearnMoreForm;
