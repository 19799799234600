import { useSelector } from 'react-redux';
import React, { FC, useState } from 'react';

import SideMenu from '../SideMenu';
import Button from '../Button';
import { selectCurrentUser } from '../../store/user/selectors';
import ROUTES from '../../routes.constants';
import logo from '../../assets/logo.png';
import { ReactComponent as HamburgerMenu } from '../../assets/hamburger-menu.svg';
import { ReactComponent as CloseIcon } from '../../assets/close-icon.svg';

import './Header.scss';

type HeaderMenuProps = {
  isSpecialEvent?: boolean;
  showMenu?: boolean;
};

const Header: FC<HeaderMenuProps> = ({ isSpecialEvent = false, showMenu = true }) => {
  const user = useSelector(selectCurrentUser);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <>
      <div className="header">
        <img
          src={logo}
          alt="logo"
          className="header-logo"
          onClick={() =>
            isSpecialEvent ? window.location.reload() : (window.location.href = ROUTES.DASHBOARD)
          }
        />
        <div className="header-right-container">
          <p className="header-support-text">Support Hotline 1.855.474.1123</p>
          {showMenu && (
            <Button className="header-button" onClick={toggleMenu}>
              {user?.name}
            </Button>
          )}

          {isMenuOpen && showMenu ? (
            <div className="header-button-container">
              <CloseIcon className="header-close-icon" onClick={closeMenu} />
            </div>
          ) : showMenu ? (
            <div className="header-button-container">
              <HamburgerMenu className="header-hamburger-menu" onClick={toggleMenu} />
            </div>
          ) : null}
        </div>
      </div>
      {showMenu && <SideMenu isOpen={isMenuOpen} onClose={closeMenu} />}
    </>
  );
};

export default Header;
