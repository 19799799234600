import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';

import Loader from '../../Loader';
import Header from '../../Header';
import Footer from '../../Footer';
import { setCurrentUserProfile } from '../../../store/user/slice';
import { selectCurrentUser, selectIsLoggedIn } from '../../../store/user/selectors';
import { useProfileQuery } from '../../../store/services/usersApi';
import { useLazyVerifyQuery } from '../../../store/services/authApi';
import ROUTES from '../../../routes.constants';
import ScrollToTop from '../../../helpers/ScrollToTop';

const AuthenticatedWrapper = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const currentUser = useSelector(selectCurrentUser);
  const [verifyUser, { data: verify, error: errorVerify, isLoading: isLoadingVerify }] =
    useLazyVerifyQuery(undefined);
  const { data: profile, isLoading: isLoadingProfile } = useProfileQuery(undefined, {
    refetchOnMountOrArgChange: true,
    skip: !!currentUser
  });

  const isLoading = useMemo(
    () => isLoadingVerify || isLoadingProfile,
    [isLoadingVerify, isLoadingProfile]
  );

  useEffect(() => {
    if (!location.state?.verified) {
      verifyUser();
    }
  }, [location]);

  useEffect(() => {
    if (profile && !currentUser && isLoggedIn) {
      dispatch(setCurrentUserProfile(profile));
    }
  }, [profile, currentUser]);

  useEffect(() => {
    if ((errorVerify || !isLoggedIn) && !isLoadingVerify) {
      navigate(ROUTES.LOGIN, { state: { from: location } });
    }
  }, [verify, errorVerify, navigate, isLoggedIn, isLoadingVerify]);

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <ScrollToTop />
      {(verify || location.state?.verified) && (
        <div className="app-layout">
          <Header />
          <div className="app-body">
            <Outlet />
          </div>
          <Footer />
        </div>
      )}
    </>
  );
};

export default AuthenticatedWrapper;
