import React, { FC, ReactNode } from 'react';

import './CoverImage.scss';

type CoverImageProps = {
  title?: string;
  backgroundImage: string;
  logoImage?: string;
  children?: ReactNode;
};

const CoverImage: FC<CoverImageProps> = ({ title, backgroundImage, logoImage, children }) => (
  <div className="cover-image-wrapper">
    <div className="cover-image" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className="cover-image-overlay" />
      {title && <h1 className="cover-image-title">{title}</h1>}
      {logoImage && <img src={logoImage} alt="organization logo" className="cover-image-logo" />}
      {children && children}
    </div>
  </div>
);
export default CoverImage;
