import React, { FC } from 'react';

import ROUTES from '../../routes.constants';
import { ReactComponent as QRCNC } from '../../assets/qr-cnc.svg';
import logo from '../../assets/logo.png';

import './Footer.scss';

type FooterProps = {
  isSpecialEvent?: boolean;
};

const Footer: FC<FooterProps> = ({ isSpecialEvent = false }) => (
  <div className="footer">
    <img
      src={logo}
      className="footer-logo"
      alt="logo"
      onClick={() =>
        isSpecialEvent ? window.location.reload() : (window.location.href = ROUTES.DASHBOARD)
      }
    />
    <div className="footer-text-container">
      <p className="footer-text">
        Support Hotline{' '}
        <a href="tel:+18554741123" className="footer-text link">
          1.855.474.1123
        </a>
      </p>
      <a href="mailto:support@cnclive.com" className="footer-text link">
        support@cnclive.com
      </a>
    </div>
    <p className="footer-text">© CNC Live. {new Date().getFullYear()} All rights reserved.</p>
    <div className="footer-qr">
      <p className="footer-text">24/7 support</p>
      <QRCNC />
    </div>
  </div>
);

export default Footer;
