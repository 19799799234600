import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useMemo, useState } from 'react';

import { StreamingDevice } from '../../types/streamingDevices';
import { useGetSpecialEventQuery } from '../../store/services/specialEventsApi';
import { formatExpiry } from '../../store/services/helpers';
import ROUTES from '../../routes.constants';
import useTitle from '../../helpers/useTitle';
import VideoGrid from '../../components/VideoGrid';
import ReactPlayer from '../../components/ReactPlayer';
import Loader from '../../components/Loader';
import CoverImage from '../../components/CoverImage';
import liveStreams from '../../assets/live-streams.png';
import { ReactComponent as CloseIcon } from '../../assets/close-icon.svg';

import './DashboardSpecialEvent.scss';

const DashboardSpecialEvent = () => {
  const { eventKey } = useParams();
  const navigate = useNavigate();

  const [selectedDevice, setSelectedDevice] = useState<StreamingDevice | undefined>();

  const email = localStorage.getItem('email');
  const { data, isLoading, isUninitialized, refetch, isError } = useGetSpecialEventQuery(
    { email, eventKey },
    { skip: !email || !eventKey }
  );

  useEffect(() => {
    const currentTime = new Date().getTime();
    const expiryTime = data?.expires_in ? new Date(data.expires_in).getTime() : null;

    if (
      data?.state === 'finished' ||
      (expiryTime !== null && expiryTime <= currentTime) ||
      (!data && !isLoading) ||
      isError
    ) {
      localStorage.removeItem('email');
      navigate(ROUTES.EVENT_LOGIN.replace(':eventKey', eventKey || ''));
    }
  }, [data, isLoading, isError]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isUninitialized && !isLoading) {
        refetch();
      }
    }, 30000);

    return () => clearInterval(interval);
  }, [isUninitialized, isLoading]);

  useTitle(data?.name || 'Event');

  const renderPlayer = useMemo(
    () =>
      selectedDevice ? (
        <>
          <div className="dashboard-special-event-stream-title-container">
            <h2>{selectedDevice.name}</h2>
            <CloseIcon
              className="dashboard-special-event-close-icon"
              onClick={() => setSelectedDevice(undefined)}
            />
          </div>
          <ReactPlayer
            videoId={selectedDevice.video_id}
            adConfigId={selectedDevice.playback_token}
          />
        </>
      ) : null,
    [selectedDevice]
  );

  return (
    <div className="dashboard-special-event">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <CoverImage backgroundImage={liveStreams} title={data?.name || 'Streaming Devices'}>
            <p className="dashboard-special-event-expires-in">
              {data?.expires_in && formatExpiry(data?.expires_in)}
            </p>
          </CoverImage>
          <div className="dashboard-special-event-container">
            {renderPlayer}
            <VideoGrid devices={data?.streams} onSelectDevice={setSelectedDevice} />
          </div>
        </>
      )}
    </div>
  );
};

export default DashboardSpecialEvent;
