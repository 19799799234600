import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '..';

const selectUserState = (state: RootState) => state.user;

export const selectIsLoggedIn = createSelector(selectUserState, (user) => user.isLoggedIn);

export const selectCurrentUser = createSelector(selectUserState, (user) => user.profile);

export const selectPasswordCreated = createSelector(
  selectUserState,
  (user) => user.passwordCreated
);
