import React, { FC } from 'react';
import classNames from 'classnames';

import './Loader.scss';

interface LoaderProps {
  className?: string;
  isCentered?: boolean;
}

/**
 * A simple loader component. By default, it will be fixed and centered.
 * You can control the param in case you need to use it in a specific element.
 * @param isCentered
 * @constructor
 */
const Loader: FC<LoaderProps> = ({ className, isCentered = true }) => (
  <div className={classNames(`loader ${isCentered ? 'centered' : ''}`, className)} />
);

export default Loader;
