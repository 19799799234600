import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import React, { ChangeEvent, FC, useEffect, useMemo, useRef, useState } from 'react';

import { ROLES } from '../../types/users';
import { selectCurrentUser } from '../../store/user/selectors';
import { uploadToS3 } from '../../store/services/s3Api';
import {
  useCreateOrganizationMutation,
  useEditOrganizationMutation,
  useGetOrganizationByIdQuery,
  useLazyGetSignedUrlQuery
} from '../../store/services/organizationsApi';
import { handleCatch } from '../../store/services/helpers';
import ROUTES from '../../routes.constants';
import useTitle from '../../helpers/useTitle';
import isFetchBaseQueryError from '../../helpers/isFetchBaseQueryError';
import Select from '../../components/Select/Select';
import Loader from '../../components/Loader';
import InputField from '../../components/Input';
import ConfirmModal from '../../components/ConfirmModal';
import Button from '../../components/Button';

import './OrganizationForm.scss';

const OrganizationForm: FC<{ myOrganizationId?: string }> = ({ myOrganizationId = '' }) => {
  const logoInputRef = useRef<HTMLInputElement>(null);
  const backgroundInputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const { id } = useParams();
  const user = useSelector(selectCurrentUser);

  useTitle('Organization');

  const { data: dataOrgById, isLoading: isLoadingGetById } = useGetOrganizationByIdQuery(
    { id: id || myOrganizationId },
    { skip: !id && !myOrganizationId }
  );

  const [getSignedUrl, { isLoading: isLoadingGetSignedUrl }] = useLazyGetSignedUrlQuery();
  const [createOrganization, { isLoading: isLoadingCreate }] = useCreateOrganizationMutation();
  const [editOrganization, { isLoading: isLoadingEdit, error: errorEditOrg }] =
    useEditOrganizationMutation();

  const [errorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [name, setName] = useState('');
  const [isLoadingS3, setLoadingS3] = useState<boolean>(false);
  const [isActive, setIsActive] = useState<boolean | undefined>(undefined);
  const [logoPreview, setLogoPreview] = useState<string | null>(null);
  const [backgroundPreview, setBackgroundPreview] = useState<string | null>(null);
  const [newLogo, setNewLogo] = useState<File | null>(null);
  const [newBackground, setNewBackground] = useState<File | null>(null);
  const [originalLogo, setOriginalLogo] = useState<string | null>(null);
  const [originalBackground, setOriginalBackground] = useState<string | null>(null);
  const isLoading = useMemo(
    () =>
      isLoadingGetById || isLoadingCreate || isLoadingEdit || isLoadingGetSignedUrl || isLoadingS3,
    [isLoadingGetById, isLoadingCreate, isLoadingEdit, isLoadingGetSignedUrl, isLoadingS3]
  );

  useEffect(() => {
    if (dataOrgById) {
      setName(dataOrgById.name);
      setIsActive(dataOrgById.active);
      setOriginalLogo(dataOrgById.logo_url);
      setOriginalBackground(dataOrgById.banner_url);
      setLogoPreview(dataOrgById.logo_url);
      setBackgroundPreview(dataOrgById.banner_url);
    }
  }, [dataOrgById]);

  useEffect(() => {
    if (errorEditOrg && isFetchBaseQueryError(errorEditOrg) && errorEditOrg?.status === 409) {
      setIsErrorModalOpen(true);
    }
  }, [errorEditOrg]);

  const handleSelectChange = (value: boolean) => {
    setIsActive(value);
  };

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newName = e.target.value;
    setName(newName);
  };

  const handleSelectLogoClick = () => {
    logoInputRef.current?.click();
  };

  const handleSelectBackgroundClick = () => {
    backgroundInputRef.current?.click();
  };

  const handleLogoChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (file) {
      setNewLogo(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setLogoPreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    } else {
      setNewLogo(null);
    }
  };

  const handleBackgroundChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (file) {
      setNewBackground(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setBackgroundPreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    } else {
      setNewBackground(null);
    }
  };

  const handleSubmit = async () => {
    try {
      if (!id) {
        const res = await createOrganization({ name, active: !!isActive }).unwrap();
        navigate(ROUTES.ORGANIZATION_EDIT.replace(':id', res.id));
      } else {
        await editOrganization({ id, name, active: !!isActive }).unwrap();
        setIsModalOpen(true);
      }
    } catch (e) {
      handleCatch(e);
    }
  };

  const handleLogoUpload = async () => {
    if (newLogo && id) {
      try {
        setLoadingS3(true);
        const { url, signed_url } = await getSignedUrl({
          id,
          fileName: newLogo.name,
          type: 'logo'
        }).unwrap();

        await uploadToS3(signed_url, newLogo);

        await editOrganization({ id, logo_url: url }).unwrap();

        setOriginalLogo(newLogo?.name);
        setNewLogo(null);
        setLoadingS3(false);
      } catch (e) {
        handleCatch(e);
      }
    }
  };

  const handleBackgroundUpload = async () => {
    if (newBackground && id) {
      try {
        setLoadingS3(true);
        const { url, signed_url } = await getSignedUrl({
          id,
          fileName: newBackground.name,
          type: 'banner'
        }).unwrap();

        await uploadToS3(signed_url, newBackground);

        await editOrganization({ id, banner_url: url }).unwrap();

        setOriginalBackground(newBackground?.name);
        setNewBackground(null);
        setLoadingS3(false);
      } catch (e) {
        handleCatch(e);
      }
    }
  };

  const resetForm = () => {
    setName('');
    setIsActive(undefined);
  };

  const handleCancel = () => {
    if (id && dataOrgById) {
      setName(dataOrgById.name);
      setIsActive(dataOrgById.active);
    } else {
      resetForm();
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);

    if (!id) resetForm();
  };

  const handleErrorModal = () => {
    setIsErrorModalOpen(false);
  };

  const handleConfirm = () => {
    navigate(ROUTES.ORGANIZATIONS);
  };

  const isDataModified = useMemo(
    () => name !== dataOrgById?.name || isActive !== dataOrgById?.active,
    [name, isActive, dataOrgById]
  );

  const shouldEnableSaveChanges = useMemo(
    () => (name.length > 0 || isActive !== undefined) && isDataModified,
    [isDataModified, name, isActive]
  );

  const shouldEnableCancel = useMemo(
    () => (id && isDataModified) || (!id && (name.length > 0 || isActive !== undefined)),
    [isDataModified, id, name, isActive]
  );

  if (isLoading) return <Loader />;

  return (
    <div className="organization-form">
      <div className="organization-form-title-container">
        <h1 className="organization-form-title">
          {myOrganizationId ? 'My' : id ? 'Edit' : 'Create'} Organization
        </h1>
        {user?.role !== ROLES.LOCAL_ADMIN && (
          <NavLink to={ROUTES.ORGANIZATIONS} className="organization-form-link">
            Back
          </NavLink>
        )}
      </div>

      <div className="organization-form-inputs-wrapper">
        <h5 className="organization-form-section-title">Information</h5>

        <div className="organization-form-inputs-container">
          <InputField
            disabled={!!myOrganizationId}
            type="text"
            className="organization-form-input"
            value={name}
            onChange={handleNameChange}
            id="name"
            label="Name *"
          />

          <Select
            disabled={!!myOrganizationId}
            label="Active"
            value={isActive}
            onChange={(value) => {
              handleSelectChange(value as boolean);
            }}
            id="select"
            options={[
              { value: true, label: 'Active' },
              { value: false, label: 'Inactive' }
            ]}
          />
        </div>

        {!myOrganizationId && (
          <div className="organization-form-buttons-container">
            <Button variant="primary" disabled={!shouldEnableSaveChanges} onClick={handleSubmit}>
              Save Changes
            </Button>
            <Button variant="secondary" onClick={handleCancel} disabled={!shouldEnableCancel}>
              Cancel
            </Button>
          </div>
        )}
      </div>

      {id && (
        <div className="organization-form-upload-wrapper">
          <div className="organization-form-upload-container">
            <h2 className="organization-form-title">Logo</h2>
            <p className="organization-form-description">Upload your agency logo.</p>
            {logoPreview && (
              <img
                src={logoPreview}
                alt="Logo Preview"
                className="organization-form-image-preview-logo"
              />
            )}
            <div className="organization-form-buttons-container">
              <input
                type="file"
                id="logo-upload"
                style={{ display: 'none' }}
                onChange={handleLogoChange}
                ref={logoInputRef}
                accept="image/svg+xml, image/png, image/jpeg"
              />
              <Button variant="primary" onClick={handleSelectLogoClick}>
                Select New Logo
              </Button>
              <Button
                variant="secondary"
                onClick={handleLogoUpload}
                disabled={Boolean(!newLogo || (originalLogo && newLogo.name === originalLogo))}
              >
                Save Changes
              </Button>
            </div>
          </div>

          <div className="organization-form-upload-container">
            <h2 className="organization-form-title">Background</h2>
            <p className="organization-form-description">Upload agency banner image.</p>
            {backgroundPreview && (
              <img
                src={backgroundPreview}
                alt="Background Preview"
                className="organization-form-image-preview-background"
              />
            )}
            <div className="organization-form-buttons-container">
              <input
                type="file"
                id="background-upload"
                style={{ display: 'none' }}
                onChange={handleBackgroundChange}
                ref={backgroundInputRef}
                accept="image/svg+xml, image/png, image/jpeg"
              />
              <Button variant="primary" onClick={handleSelectBackgroundClick}>
                Select New Background
              </Button>
              <Button
                variant="secondary"
                onClick={handleBackgroundUpload}
                disabled={Boolean(
                  !newBackground ||
                    (originalBackground && newBackground.name === originalBackground)
                )}
              >
                Save Changes
              </Button>
            </div>
          </div>
        </div>
      )}
      <ConfirmModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onConfirm={handleConfirm}
        message={`Organization successfully ${id ? 'modified' : 'created'}`}
        cancelText="Stay on this page"
        confirmText="Go to all organizations"
        variant="primary"
      />

      {isFetchBaseQueryError(errorEditOrg) && (
        <ConfirmModal
          isOpen={errorModalOpen}
          onConfirm={handleErrorModal}
          message={errorEditOrg.data.message}
          confirmText="Ok"
          variant="primary"
        />
      )}
    </div>
  );
};

export default OrganizationForm;
