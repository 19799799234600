const BASE_ROUTES = {
  DASHBOARD: '/',
  ADMIN: '/admin',
  PROFILE: '/profile',
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgot-password',
  LEARN_MORE: '/signup',
  CREATE_PASSWORD: '/create-password',
  EVENT_LOGIN: '/event/login/:eventKey',
  SPECIAL_EVENT: '/event/dashboard/:eventKey'
};

const ROUTES = {
  ...BASE_ROUTES,
  ORGANIZATIONS: `${BASE_ROUTES.ADMIN}/organizations`,
  ORGANIZATION_EDIT: `${BASE_ROUTES.ADMIN}/organizations/edit/:id`,
  ORGANIZATION_CREATE: `${BASE_ROUTES.ADMIN}/organizations/create`,
  USERS: `${BASE_ROUTES.ADMIN}/users`,
  USER_EDIT: `${BASE_ROUTES.ADMIN}/users/edit/:id`,
  USER_CREATE: `${BASE_ROUTES.ADMIN}/users/create`,
  STREAMING_DEVICES: `${BASE_ROUTES.ADMIN}/streaming-devices`,
  STREAMING_DEVICES_CREATE: `${BASE_ROUTES.ADMIN}/streaming-devices/create`,
  STREAMING_DEVICES_EDIT: `${BASE_ROUTES.ADMIN}/streaming-devices/edit/:id`,
  EVENTS: `${BASE_ROUTES.ADMIN}/events`,
  EVENT_CREATE: `${BASE_ROUTES.ADMIN}/event/create`,
  EVENT_EDIT: `${BASE_ROUTES.ADMIN}/event/edit/:id`
};

export default ROUTES;
