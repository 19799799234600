import React, { FC } from 'react';

import VideoCard from '../VideoCard';
import { StreamingDevice } from '../../types/streamingDevices';
import { Event } from '../../types/events';

import './VideoGrid.scss';

type VideoGridProps = {
  devices?: StreamingDevice[];
  onSelectDevice: (device: StreamingDevice) => void;
  varAdmin?: boolean;
  allOrganizationFlag?: boolean;
  dataEvents?: Event[];
};
const VideoGrid: FC<VideoGridProps> = ({
  devices,
  onSelectDevice,
  varAdmin,
  allOrganizationFlag,
  dataEvents
}) => (
  <div className="video-grid">
    {devices?.map((device) => (
      <VideoCard
        key={device.id || device.entry_point}
        device={device}
        onSelectDevice={onSelectDevice}
        varAdmin={varAdmin}
        allOrganizationFlag={allOrganizationFlag}
        eventName={dataEvents?.find((event) => event.stream_ids.includes(device.id))?.name}
      />
    ))}
  </div>
);

export default VideoGrid;
