import React, { FC, useEffect, useRef, useState, ClipboardEvent } from 'react';
import classNames from 'classnames';

import { validateEmail } from '../../helpers/validators';
import { ReactComponent as DeleteIcon } from '../../assets/delete-icon.svg';

import './EmailPillInput.scss';

interface EmailPillInputProps {
  label: string;
  emails?: string[];
  onChange: (emails: string[]) => void;
}

interface EditableEmail {
  index: number;
  value: string;
}

const EmailPillInput: FC<EmailPillInputProps> = ({ label, emails, onChange }) => {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [editableEmail, setEditableEmail] = useState<EditableEmail | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (emails) {
      onChange(emails);
    }
  }, [emails]);

  const enterEditMode = (index: number) => {
    if (emails) {
      setEditableEmail({ index, value: emails[index] });
    }
  };

  const exitEditMode = () => {
    setEditableEmail(null);

    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  const updateEmail = (newEmail: string, index: number) => {
    if (emails) {
      onChange(emails.map((email, i) => (i === index ? newEmail : email)));
    }
  };

  const removeEmail = (index: number) => {
    if (emails) {
      onChange(emails.filter((_, i) => i !== index));

      if (editableEmail && editableEmail.index === index) {
        exitEditMode();
      }
    }
  };

  const handleSubmitEmail = () => {
    if (editableEmail?.value || inputRef.current?.value) {
      const newEmail = editableEmail ? editableEmail.value.trim() : inputRef.current?.value.trim();
      const error = validateEmail(newEmail as string);
      setErrorMessage(error);

      if (!error) {
        if (newEmail && !emails?.includes(newEmail)) {
          if (editableEmail) {
            updateEmail(newEmail, editableEmail.index);
            setEditableEmail(null);
          } else {
            onChange([newEmail, ...(emails ?? [])]);

            if (inputRef.current) {
              inputRef.current.value = '';
            }
          }
        }

        setErrorMessage('');
      }
    } else {
      exitEditMode();
      setErrorMessage('');
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSubmitEmail();
    }
  };

  const handlePaste = (event: ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();
    const pastedText = event.clipboardData.getData('text');
    const newEmails = pastedText
      .replaceAll('"', '')
      .split(/[\s,]+/)
      .filter((email) => validateEmail(email) === '' && !emails?.includes(email));
    onChange([...newEmails, ...(emails ?? [])]);
  };

  return (
    <div className="email-pill-input">
      <div className="label-container">
        <label>{label}</label>
      </div>

      <div className="pills-container" onClick={() => inputRef.current?.focus()}>
        <div className="add-email-container">
          {editableEmail === null && (
            <>
              <input
                className="add-email-input"
                type="text"
                onKeyDown={handleKeyDown}
                onPaste={handlePaste}
                ref={inputRef}
                placeholder="Add email and press enter"
                onBlur={handleSubmitEmail}
              />
              <span className="domain-pill-input-confirm" onClick={handleSubmitEmail}>
                INVITE
              </span>
            </>
          )}
        </div>
        {!!emails?.length && <div className="separator" />}
        {emails?.map((email, index) =>
          editableEmail && editableEmail.index === index ? (
            <div key={index} className="pill-edit-mode">
              <input
                type="text"
                value={editableEmail.value}
                onChange={(e) => setEditableEmail({ ...editableEmail, value: e.target.value })}
                onKeyDown={handleKeyDown}
                autoFocus
              />
              <span
                className="domain-pill-input-confirm"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleSubmitEmail();
                }}
              >
                Save
              </span>
              <span
                className="domain-pill-input-confirm"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  exitEditMode();
                  setErrorMessage('');
                }}
              >
                Cancel
              </span>
            </div>
          ) : (
            <div key={index} className="pill">
              {email}
              <button type="button" onClick={() => enterEditMode(index)}>
                &#9998;
              </button>
              <button type="button" onClick={() => removeEmail(index)}>
                <DeleteIcon />
              </button>
            </div>
          )
        )}
      </div>
      <div className={classNames('error-message')}>{errorMessage}</div>
    </div>
  );
};

export default EmailPillInput;
