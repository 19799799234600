import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { Organization } from '../../types/organizations';
import { ApiListResponse, ApiListRequestParams } from '../../types/api';
import config from '../../config';

import { buildQueryParams } from './helpers';

type CreateOrganizationParams = {
  name: string;
  active: boolean;
};

type SignedUrlResponse = {
  url: string;
  signed_url: string;
};

export const organizationsApi = createApi({
  reducerPath: 'organizationsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${config.apiUrl}/organizations`,
    credentials: 'include'
  }),
  tagTypes: ['Organization', 'OrganizationList'],

  endpoints: (builder) => ({
    organizations: builder.query<ApiListResponse<Organization>, ApiListRequestParams>({
      query: (params) => buildQueryParams('/search', params),
      providesTags: ['OrganizationList']
    }),
    createOrganization: builder.mutation<Organization, CreateOrganizationParams>({
      query: (organizationData) => ({
        url: `/`,
        method: 'POST',
        body: organizationData
      }),
      invalidatesTags: ['Organization', 'OrganizationList']
    }),
    editOrganization: builder.mutation<Organization, Partial<Organization>>({
      query: ({ id, ...rest }) => ({
        url: `/${id}`,
        method: 'PUT',
        body: rest
      }),
      invalidatesTags: ['Organization', 'OrganizationList']
    }),
    getOrganizationById: builder.query<Organization, { id: string | undefined }>({
      query: ({ id }) => `/${id}`,
      providesTags: ['Organization']
    }),
    getSignedUrl: builder.query<SignedUrlResponse, { id: string; fileName: string; type: string }>({
      query: ({ id, fileName, type }) => ({
        url: `/${id}/signed-url`,
        params: { fileName, type }
      })
    })
  })
});

export const {
  useOrganizationsQuery,
  useGetOrganizationByIdQuery,
  useLazyGetOrganizationByIdQuery,
  useCreateOrganizationMutation,
  useEditOrganizationMutation,
  useLazyGetSignedUrlQuery
} = organizationsApi;
