import { ColumnsType } from 'rc-table/lib/interface';

import Checkbox from '../Checkbox/Checkbox';

import { SortDirection } from './components/SortableColumn/SortableColumn';
import RowSelector from './components/RowSelector';

export interface Sort<T> {
  key?: keyof T;
  dir?: SortDirection;
}

export type TableSelection = 'single' | 'multi';

export type TableRecord = {
  id: string;
  [key: string]: unknown;
};

type SelectionColumnProps = {
  selectionType?: TableSelection;
  selectAllCheck?: boolean;
  onSelectAllChange?: () => void;
};

export function getSelectionColumn<T>({
  selectionType,
  selectAllCheck,
  onSelectAllChange
}: SelectionColumnProps): ColumnsType<T> {
  if (!selectionType) return [];

  const isSingle = selectionType === 'single';

  return [
    {
      title: !isSingle && onSelectAllChange && (
        <Checkbox centered checked={!!selectAllCheck} onChange={onSelectAllChange} />
      ),
      width: 80,
      align: 'center',
      render: (value, record) => (
        <RowSelector record={record as TableRecord} selectionType={selectionType} />
      )
    }
  ];
}
