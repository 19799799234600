import React from 'react';
import classNames from 'classnames';

import './Checkbox.scss';

interface CheckboxProps {
  checked: boolean;
  onChange: (checked: boolean) => void;
  label?: string;
  centered?: boolean;
  disabled?: boolean;
}

const Checkbox: React.FC<CheckboxProps> = ({ checked, onChange, label, centered, disabled }) => (
  <label className={classNames('checkbox-container', { centered })}>
    <input
      type="checkbox"
      checked={checked}
      onChange={(e) => {
        e.stopPropagation();
        onChange(e.target.checked);
      }}
      className="checkbox-input"
      disabled={disabled}
    />
    <span className="checkbox-custom" />
    {label && <span className="checkbox-label">{label}</span>}
  </label>
);

export default Checkbox;
