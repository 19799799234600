import React, { FC } from 'react';

import useTitle from '../../helpers/useTitle';
import AuthLayout from '../../components/AuthLayout';

import LearnMoreForm from './Form/LearnMoreForm';

const LearnMore: FC = () => {
  useTitle('Learn More');

  return (
    <AuthLayout>
      <LearnMoreForm />
    </AuthLayout>
  );
};

export default LearnMore;
