import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import React, { ChangeEvent, FC, KeyboardEvent, useEffect, useState } from 'react';

import { selectIsLoggedIn } from '../../../store/user/selectors';
import { useForgotPasswordMutation } from '../../../store/services/authApi';
import ROUTES from '../../../routes.constants';
import { validateEmail } from '../../../helpers/validators';
import isFetchBaseQueryError from '../../../helpers/isFetchBaseQueryError';
import debounceValidation from '../../../helpers/debounceValidation';
import Loader from '../../../components/Loader';
import InputField from '../../../components/Input';
import Button from '../../../components/Button';
import logo from '../../../assets/logo.png';

import './ForgotPasswordForm.scss';

const ForgotPasswordForm: FC = () => {
  const [forgotPassword, { isLoading, isError }] = useForgotPasswordMutation();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [submitError, setSubmitError] = useState('Something went wrong');
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || ROUTES.DASHBOARD;

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    debounceValidation(() => setEmailError(validateEmail(newEmail)));
  };

  useEffect(() => {
    if (isLoggedIn) {
      navigate(from, { replace: true });
    }
  }, [isLoggedIn, navigate, from]);

  const handleSubmit = async () => {
    try {
      setSubmitError('');
      await forgotPassword({ username: email }).unwrap();
      navigate(ROUTES.CREATE_PASSWORD, { state: { code: true, email } });
    } catch (err) {
      if (isFetchBaseQueryError(err)) {
        if (err.data.message === 'Username/client id combination not found.') {
          setSubmitError('Please enter a valid email address');
        } else if (
          err.data.message === 'User password cannot be reset in the current state' ||
          err.data.message === 'Blocked user'
        ) {
          setSubmitError(
            'Your account has been deactivated. You cannot reset your password. If you believe this has happened in error, please contact CNC support.'
          );
        }
      }

      // eslint-disable-next-line no-console
      console.error(err);
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  if (isLoggedIn) return null;

  return (
    <section className="forgot-form">
      <img src={logo} alt="logo" className="forgot-form-logo" />
      {isLoading && <Loader />}
      <header>
        <h2>Forgot Password</h2>
        <h5>We&apos;ll send you an email with a code to reset your password.</h5>
      </header>

      <InputField
        className="forgot-form-email-input"
        type="email"
        value={email}
        onChange={handleEmailChange}
        onKeyDown={handleKeyDown}
        id="email"
        label="Email Address *"
        link="eventaccesslink"
        isExternalLink
        error={emailError}
      />

      <div className={`forgot-form-error-message ${isError ? 'forgot-form-visible' : ''}`}>
        {submitError}
      </div>

      <div className="forgot-form-actions">
        <Button
          disabled={isLoading || !!emailError || !email}
          onClick={handleSubmit}
          className="forgot-form-submit-button"
        >
          Reset Password
        </Button>
        <Button onClick={() => navigate(-1)} variant="link">
          Back
        </Button>
      </div>
    </section>
  );
};

export default ForgotPasswordForm;
