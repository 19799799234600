import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import { useTableContext } from '../../providers/CNCTable.context';
import { ReactComponent as ChevronDown } from '../../../../assets/chevron-down.svg';

import './SortableColumn.scss';

export type SortDirection = 'ASC' | 'DESC';

interface Props<T> {
  label: string;
  sortKey: keyof T;
  invertedArrow?: boolean;
}

function SortableColumn<T>({ sortKey, label, invertedArrow = false }: Props<T>) {
  const { sort, setSort } = useTableContext<T>();
  const [dir, setDir] = useState<SortDirection>();

  const handleClick = () => {
    switch (dir) {
      case 'DESC':
        setDir('ASC');
        break;
      case 'ASC':
        setDir('DESC');
        break;
      default:
        setDir('DESC');
        break;
    }
  };

  useEffect(() => {
    if (dir) {
      setSort({ key: sortKey, dir });
    } else {
      setSort({});
    }
  }, [dir]);

  const isSorted = sort?.key === sortKey;

  return (
    <span
      className={classNames(
        'sortable-column',
        isSorted ? (invertedArrow ? (dir === 'ASC' ? 'DESC' : 'ASC') : dir) : ''
      )}
      onClick={handleClick}
    >
      <span className="label">{label}</span>

      <span className="icons">
        <ChevronDown />
      </span>
    </span>
  );
}

export default SortableColumn;
