import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';

import { ROLES } from '../../types/users';
import { selectCurrentUser } from '../../store/user/selectors';
import ROUTES from '../../routes.constants';
import CoverImage from '../../components/CoverImage';
import AdminNav from '../../components/AdminNav';
import users from '../../assets/users.jpg';
import streamingDevices from '../../assets/streaming-devices.png';
import organizations from '../../assets/organizations.jpg';
import events from '../../assets/events.jpg';

import './Admin.scss';

const Admin = () => {
  const user = useSelector(selectCurrentUser);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const coverImage = useMemo(() => {
    switch (true) {
      case pathname.includes(ROUTES.STREAMING_DEVICES):
        return streamingDevices;
      case pathname.includes(ROUTES.EVENTS):
      case pathname.includes(ROUTES.EVENT_CREATE):
      case pathname.indexOf('/event/edit') > -1:
        return events;
      case pathname.includes(ROUTES.USERS):
        return users;
      default:
        return organizations;
    }
  }, [location.pathname]);

  if (!user || user.role === ROLES.VIEWER) {
    navigate(ROUTES.DASHBOARD);
  }

  return user?.role === ROLES.VAR || user?.role === ROLES.LOCAL_ADMIN ? (
    <div className="admin">
      <CoverImage backgroundImage={coverImage} />
      <div className="admin-container">
        {!pathname.includes('edit') && !pathname.includes('create') && <AdminNav />}
        <Outlet />
      </div>
    </div>
  ) : null;
};

export default Admin;
