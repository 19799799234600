const config = {
  env: import.meta.env.NODE_ENV,
  version: import.meta.env.VITE_ENVIRONMENT,
  awsRegion: import.meta.env.VITE_AWS_REGION,
  apiUrl: import.meta.env.VITE_API_URL,
  apiClientUrl: import.meta.env.VITE_API_CLIENT_URL,
  baseUrl: import.meta.env.VITE_BASE_URL,
  accountId: import.meta.env.VITE_BC_ACCOUNT_ID,
  playerId: import.meta.env.VITE_BC_PLAYER_ID
};

export default config;
