import React from 'react';
import { DefaultRecordType } from 'rc-table/lib/interface';

import TableContextProvider from './providers/CNCTable.provider';
import CNCTableInternal, { CNCTableInternalProps } from './CNCTable.internal';

interface CNCTableProps<T> extends CNCTableInternalProps<T> {}

function CNCTable<T extends DefaultRecordType>(props: CNCTableProps<T>) {
  return (
    <TableContextProvider data={props.data?.items}>
      <CNCTableInternal {...props} />
    </TableContextProvider>
  );
}

export default CNCTable;
