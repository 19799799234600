import React, { FC } from 'react';

import Checkbox from '../Checkbox/Checkbox';

import './NotificationsCheckboxGroup.scss';

export type NotificationType = 'inApp' | 'email';

interface CheckboxGroupProps {
  inAppNotifications: boolean;
  emailNotifications: boolean;
  handleNotificationChange: (type: NotificationType, checked: boolean) => void;
}

const NotificationsCheckboxGroup: FC<CheckboxGroupProps> = ({
  handleNotificationChange,
  inAppNotifications,
  emailNotifications
}) => (
  <div className="notifications-group">
    <label className="notifications-group-label">Active Streams Notifications</label>
    <div className="notifications-group-container">
      <Checkbox
        label="In-app Notifications"
        checked={inAppNotifications}
        onChange={(checked) => handleNotificationChange('inApp', checked)}
      />

      <Checkbox
        label="Email Notifications"
        checked={emailNotifications}
        onChange={(checked) => handleNotificationChange('email', checked)}
      />
    </div>
  </div>
);

export default NotificationsCheckboxGroup;
