import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import React, { FC, useEffect, useMemo } from 'react';

import { ROLES } from '../../types/users';
import { selectCurrentUser } from '../../store/user/selectors';
import { useLogoutMutation } from '../../store/services/authApi';
import ROUTES from '../../routes.constants';
import { ReactComponent as CloseIcon } from '../../assets/close-icon.svg';

import './SideMenu.scss';

type SideMenuProps = {
  isOpen: boolean;
  onClose: () => void;
};

const SideMenu: FC<SideMenuProps> = ({ isOpen, onClose }) => {
  const user = useSelector(selectCurrentUser);
  const [logout] = useLogoutMutation();

  const onLogout = async () => {
    await logout();
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }

    return () => {
      document.body.style.overflow = '';
    };
  }, [isOpen]);

  const renderUserRole = useMemo(() => {
    let text;

    switch (user?.role) {
      case ROLES.VAR:
        text = 'VAR';
        break;
      case ROLES.LOCAL_ADMIN:
        text = 'Local Admin';
        break;
      default:
        text = 'Viewer';
        break;
    }

    return <div className="side-menu-role">{text}</div>;
  }, [user]);

  return (
    <div className={`side-menu ${isOpen ? 'side-menu-open' : ''}`} onClick={onClose}>
      <div className="side-menu-content" onClick={(e) => e.stopPropagation()}>
        <div className="side-menu-top-container">
          <p>My Account</p>
          <CloseIcon className="side-menu-close-icon" onClick={onClose} />
        </div>

        <div className="side-menu-user-container">
          <div className="side-menu-name-container">
            <div className="side-menu-role-container">
              <p className="side-menu-name">{user?.name}</p>
              {renderUserRole}
            </div>

            {user?.organization?.name && (
              <p className="side-menu-organization">{user?.organization?.name}</p>
            )}
          </div>
        </div>

        <div className="side-menu-links-container">
          <NavLink onClick={onClose} to={ROUTES.DASHBOARD} className="side-menu-link">
            Dashboard
          </NavLink>
          {user?.role !== ROLES.VIEWER && (
            <NavLink onClick={onClose} to={ROUTES.ADMIN} className="side-menu-link">
              Admin
            </NavLink>
          )}
          <NavLink onClick={onClose} to={ROUTES.PROFILE} className="side-menu-link">
            Profile
          </NavLink>
          <div className="side-menu-line" />
          <p onClick={onLogout} className="side-menu-link">
            Log Out
          </p>
        </div>
      </div>

      <div className="side-menu-overlay" />
    </div>
  );
};

export default SideMenu;
