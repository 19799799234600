import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import React, { FC } from 'react';

import { setPasswordCreated } from '../../../store/user/slice';
import ROUTES from '../../../routes.constants';
import Button from '../../../components/Button';

import './PasswordSuccess.scss';

const PasswordSuccess: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <section className="password-success">
      <header>
        <h2>Password Successfully Created</h2>
        <h5>Please log in with the new password to access the platform. </h5>
      </header>

      <div className="actions">
        <Button
          onClick={() => {
            dispatch(setPasswordCreated(false));
            navigate(ROUTES.LOGIN);
          }}
          className="submit-button"
        >
          Log In
        </Button>
      </div>
    </section>
  );
};

export default PasswordSuccess;
