import React, { useEffect, useState } from 'react';
import { ColumnsType, DefaultRecordType } from 'rc-table/lib/interface';
import Table, { TableProps } from 'rc-table';
import classNames from 'classnames';

import Pagination from '../Pagination';
import Loader from '../Loader';
import { ApiListResponse } from '../../types/api';

import { useTableContext } from './providers/CNCTable.context';
import { getSelectionColumn, Sort, TableSelection } from './helpers';

import './CNCTable.scss';

export interface CNCTableInternalProps<T> extends Omit<TableProps<T>, 'data'> {
  data?: ApiListResponse<T>;
  loading?: boolean;
  selectAll?: boolean;
  showPagination?: boolean;
  label?: string;
  selectionType?: TableSelection;
  scrollX?: number | true | string;
  scrollY?: number | string;
  pageSize?: number;
  pageNumber?: number;
  onSelect?: (items: T[]) => void;
  onSort?: (sort: Sort<T>) => void;
  onPageChange?: (page: number) => void;
  initialSelectedIds?: string[];
}

function CNCTableInternal<T extends DefaultRecordType>({
  data,
  loading,
  className,
  selectionType,
  emptyText = 'No matching records found',
  rowKey = 'id',
  scrollX = 700,
  scrollY,
  tableLayout = 'fixed',
  pageSize = 10,
  pageNumber,
  onSort,
  onSelect,
  onPageChange,
  label,
  showPagination = true,
  selectAll = false,
  initialSelectedIds,
  ...props
}: CNCTableInternalProps<T>) {
  const { selection, sort, setSelection } = useTableContext<T>();
  const [selectAllCheck, setSelectAllCheck] = useState(false);
  const [initialSelectionSet, setInitialSelectionSet] = useState(false);
  const [paginationKey, setPaginationKey] = useState(0);

  useEffect(() => {
    if (
      !initialSelectionSet &&
      initialSelectedIds &&
      initialSelectedIds.length > 0 &&
      data?.items
    ) {
      const initialSelectedDevices = data.items.filter((item) =>
        initialSelectedIds.includes(item.id)
      );
      setSelection(initialSelectedDevices);
      setInitialSelectionSet(true);
    }
  }, [initialSelectedIds, data]);

  useEffect(() => {
    if (data?.items && selection.length === data.items.length) {
      setSelectAllCheck(true);
    } else {
      setSelectAllCheck(false);
    }
  }, [selection, data?.items]);

  useEffect(() => {
    onSelect?.(selection);
  }, [selection]);

  useEffect(() => {
    onSort?.(sort);
    setPaginationKey((prev) => prev + 1); // Increment pagination key on sort change to re-render pagination component
  }, [sort]);

  const handlePageChange = (page: number) => {
    onPageChange?.(page);
  };

  const onSelectAllChange = () => {
    const newSelectAllState = !selectAllCheck;
    setSelectAllCheck(newSelectAllState);

    if (newSelectAllState) {
      setSelection(data?.items || []);
    } else {
      setSelection([]);
    }
  };

  const columns: ColumnsType<T> = [
    ...getSelectionColumn<T>({ selectionType, selectAllCheck, onSelectAllChange }),
    ...(props.columns || [])
  ];

  return (
    <div className={className}>
      {label && (
        <div className="label-container">
          <label>{label}</label>
        </div>
      )}

      <Table
        {...props}
        rowKey={rowKey}
        data={loading ? [] : data?.items}
        tableLayout={tableLayout}
        className={classNames('cnc-table', { ['select-all']: selectAll })}
        columns={columns}
        scroll={{ x: scrollX, y: scrollY }}
        emptyText={loading ? <Loader className="loader" /> : emptyText}
      />

      {showPagination && (
        <Pagination
          key={paginationKey}
          total={data?.total}
          pageNumber={pageNumber}
          pageSize={pageSize}
          onChange={handlePageChange}
        />
      )}
    </div>
  );
}

export default CNCTableInternal;
