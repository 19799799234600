import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import React, { FC, useEffect } from 'react';

import Loader from '../../Loader';
import { selectIsLoggedIn } from '../../../store/user/selectors';
import { useVerifyQuery } from '../../../store/services/authApi';
import ROUTES from '../../../routes.constants';

const UnauthenticatedWrapper: FC = () => {
  const navigate = useNavigate();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const location = useLocation();
  const session = location.state?.session;
  const from = location.state?.from?.pathname || ROUTES.DASHBOARD;
  const { isLoading } = useVerifyQuery(undefined, {
    skip: session || isLoggedIn,
    refetchOnMountOrArgChange: true
  });

  useEffect(() => {
    if (isLoggedIn && !isLoading) {
      navigate(from, { replace: true, state: { verified: true } });
    }
  }, [isLoggedIn, navigate, from, isLoading]);

  if (isLoading) return <Loader />;

  return !isLoading && !isLoggedIn ? <Outlet /> : null;
};

export default UnauthenticatedWrapper;
