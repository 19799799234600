import { ColumnsType } from 'rc-table/lib/interface';

import { User } from '../../types/users';
import ROUTES from '../../routes.constants';
import TableActions from '../../components/CNCTable/components/TableActions';
import SortableColumn from '../../components/CNCTable/components/SortableColumn';

export const usersColumns = ({
  onDelete,
  onActivate,
  onDeactivate,
  currentUserId,
  isVar
}: {
  onDelete: (id: string) => void;
  onActivate: (id: string) => void;
  onDeactivate: (id: string) => void;
  currentUserId?: string;
  isVar?: boolean;
}): ColumnsType<User> => [
  {
    title: <SortableColumn<User> label="Name" sortKey="name" />,
    dataIndex: 'name',
    align: 'left',
    width: 200
  },
  {
    title: <SortableColumn<User> label="Role" sortKey="role" />,
    dataIndex: 'role',
    align: 'left',
    width: 130
  },
  {
    title: <SortableColumn label="Organization" sortKey="organization" />,
    width: 180,
    align: 'left',
    render: ({ organization }) => organization?.name
  },
  {
    title: <SortableColumn label="Status" sortKey="active" invertedArrow />,
    width: 130,
    align: 'left',
    render: ({ active }) => (active ? ' Active' : 'Deactivated')
  },
  {
    title: <SortableColumn<User> label="Created date" sortKey="created_at" />,
    width: 140,
    dataIndex: 'created_at',
    render: (created_at) => new Date(created_at).toLocaleDateString(),
    align: 'center'
  },
  {
    title: null,
    align: 'left',
    width: 240,
    render: ({ id, active }) => {
      const isMe = currentUserId === id;

      return (
        <TableActions
          id={id}
          editUrl={ROUTES.USER_EDIT.replace(':id', id)}
          onDelete={!isMe && isVar ? () => onDelete(id) : undefined}
          onDeactivate={active && !isMe ? () => onDeactivate(id) : undefined}
          onActivate={!active && !isMe ? () => onActivate(id) : undefined}
        />
      );
    }
  }
];
