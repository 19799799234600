import { useSelector } from 'react-redux';
import React, { FC } from 'react';

import { selectPasswordCreated } from '../../store/user/selectors';
import useTitle from '../../helpers/useTitle';
import AuthLayout from '../../components/AuthLayout';

import PasswordSuccess from './PasswordSuccess';
import CreatePasswordForm from './Form/CreatePasswordForm';

const CreatePassword: FC = () => {
  const passwordCreated = useSelector(selectPasswordCreated);
  useTitle('Create Password');

  return <AuthLayout>{passwordCreated ? <PasswordSuccess /> : <CreatePasswordForm />}</AuthLayout>;
};

export default CreatePassword;
