import { useNavigate } from 'react-router-dom';
import React, { FC, ReactNode } from 'react';

import ROUTES from '../../routes.constants';
import logo from '../../assets/logo.png';

import './AuthLayout.scss';

interface AuthLayoutProps {
  children: ReactNode;
  isSpecialEventLogin?: boolean;
}

const AuthLayout: FC<AuthLayoutProps> = ({ children, isSpecialEventLogin = false }) => {
  const navigate = useNavigate();

  return (
    <div className="auth-layout">
      <div className="left-container">
        <img
          src={logo}
          alt="CNC Logo"
          onClick={() => (isSpecialEventLogin ? window.location.reload() : navigate(ROUTES.LOGIN))}
        />
      </div>

      <div className="right-container">
        {children}

        <div className="support-container">
          {!isSpecialEventLogin && (
            <a href="https://cnctechnologies.com/" className="link" rel="noopener noreferrer">
              Go to CNC Technologies
            </a>
          )}
          <p className="support-text">Support Hotline 1.855.474.1123</p>
          <a href="mailto:support@cnclive.com" className="link">
            support@cnclive.com
          </a>
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
