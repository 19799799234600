import React, { FC } from 'react';

import useTitle from '../../helpers/useTitle';
import AuthLayout from '../../components/AuthLayout';

import LoginForm from './LoginForm';

const Login: FC = () => {
  useTitle('Login');

  return (
    <AuthLayout>
      <LoginForm />
    </AuthLayout>
  );
};

export default Login;
