import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { User } from '../../types/users';

export interface UserState {
  isLoggedIn: boolean;
  passwordCreated: boolean;
  profile: User | null;
}

const initialState: UserState = {
  isLoggedIn: false,
  passwordCreated: false,
  profile: null
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setIsLoggedIn: (state, action: PayloadAction<boolean>) => {
      if (!action.payload) {
        localStorage.removeItem('currentOrg');
      }

      state.isLoggedIn = action.payload;
    },
    setPasswordCreated: (state, action: PayloadAction<boolean>) => {
      state.passwordCreated = action.payload;
    },
    setCurrentUserProfile: (state, action: PayloadAction<User | null>) => {
      state.profile = action.payload;
    }
  }
});

export const { setIsLoggedIn, setPasswordCreated, setCurrentUserProfile } = userSlice.actions;

export default userSlice.reducer;
