import React, { FC } from 'react';

import useTitle from '../../helpers/useTitle';
import AuthLayout from '../../components/AuthLayout';

import ForgotPasswordForm from './Form/ForgotPasswordForm';

const ForgotPassword: FC = () => {
  useTitle('Forgot Password');

  return (
    <AuthLayout>
      <ForgotPasswordForm />
    </AuthLayout>
  );
};

export default ForgotPassword;
