import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';

import { ROLES } from '../../types/users';
import { OrganizationNameAndID } from '../../types/organizations';
import { selectCurrentUser } from '../../store/user/selectors';
import {
  useCreateUserMutation,
  useDeactivateUserMutation,
  useDeleteUserMutation,
  useEditUserMutation,
  useGetUserByIdQuery,
  useReactivateUserMutation,
  UserUpdateParams
} from '../../store/services/usersApi';
import { useOrganizationsQuery } from '../../store/services/organizationsApi';
import { handleCatch } from '../../store/services/helpers';
import ROUTES from '../../routes.constants';
import {
  validateEmail,
  validateName,
  validateNumber,
  validatePassword
} from '../../helpers/validators';
import useTitle from '../../helpers/useTitle';
import debounceValidation from '../../helpers/debounceValidation';
import Select from '../../components/Select';
import { NotificationType } from '../../components/NotificationsCheckboxGroup/NotificationsCheckboxGroup';
import NotificationsCheckboxGroup from '../../components/NotificationsCheckboxGroup';
import Loader from '../../components/Loader';
import InputField from '../../components/Input';
import Criteria from '../../components/Criteria';
import ConfirmModal from '../../components/ConfirmModal';
import Button from '../../components/Button';

import './UserForm.scss';

const UserForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const currentUser = useSelector(selectCurrentUser);
  useTitle('User');

  // Mutations
  const [createUser, { error: errorCreate, isLoading: isLoadingCreateUser }] =
    useCreateUserMutation();
  const [editUser, { error: errorUpdate, isLoading: isLoadingEditUser }] = useEditUserMutation();
  const [deleteUser, { error: errorDelete, isLoading: isLoadingDeleteUser }] =
    useDeleteUserMutation();
  const [reactivateUser, { isLoading: isLoadingReactivateUser, error: errorReactivate }] =
    useReactivateUserMutation();
  const [deactivateUser, { isLoading: isLoadingDeactivateUser, error: errorDeactivate }] =
    useDeactivateUserMutation();

  // Queries
  const { data: userData, isLoading: isLoadingUserData } = useGetUserByIdQuery(
    { id },
    { skip: !id }
  );

  const { data: organizations } = useOrganizationsQuery(
    { q: 'active:true' },
    {
      skip: currentUser?.role !== ROLES.VAR
    }
  );

  // States
  const [isActivateModalOpen, setIsActivateModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDeactivateModalOpen, setIsDeactivateModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [position, setPosition] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [organization, setOrganization] = useState<OrganizationNameAndID | undefined>({
    name: '',
    id: ''
  });
  const [number, setNumber] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [role, setRole] = useState<ROLES>();
  const [inAppNotifications, setInAppNotifications] = useState(true); // by default always true
  const [emailNotifications, setEmailNotifications] = useState(false);

  // Errors states
  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [numberError, setNumberError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');

  const [criteria, setCriteria] = useState({
    minLength: false,
    uppercase: false,
    lowercase: false,
    specialChar: false,
    number: false
  });

  const areAllCriteriaMet = useMemo(
    () => Object.values(criteria).every((isMet) => isMet),
    [criteria]
  );

  const isMe = useMemo(() => currentUser?.id === id, [id, currentUser]);

  const isLoading = useMemo(
    () =>
      loading ||
      isLoadingUserData ||
      isLoadingCreateUser ||
      isLoadingEditUser ||
      isLoadingDeleteUser ||
      isLoadingReactivateUser ||
      isLoadingDeactivateUser,
    [
      loading,
      isLoadingUserData,
      isLoadingCreateUser,
      isLoadingEditUser,
      isLoadingDeleteUser,
      isLoadingReactivateUser,
      isLoadingDeactivateUser
    ]
  );

  useEffect(() => {
    setCriteria({
      minLength: password.length >= 8,
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      specialChar: /[@$!%*?&]/.test(password),
      number: /\d/.test(password)
    });
  }, [password]);

  const organizationOptions = useMemo(
    () =>
      currentUser?.role === ROLES.VAR
        ? organizations?.items.map((org) => ({
            value: org.id,
            label: org.name
          })) || []
        : [
            {
              label: currentUser?.organization.name || '',
              value: currentUser?.organization.id || ''
            }
          ],
    [organizations, currentUser]
  );

  useEffect(() => {
    if (userData) {
      setName(userData.name);
      setEmail(userData.username);

      if (currentUser?.role === ROLES.LOCAL_ADMIN) {
        setOrganization({
          id: currentUser?.organization?.id || '',
          name: currentUser?.organization?.name
        });
      } else {
        setOrganization({
          id: userData?.organization?.id,
          name: userData?.organization?.name
        });
      }

      setPosition(userData.position_title);
      setNumber(userData.phone_number.replace('+1', ''));
      setRole(userData.role);
      setInAppNotifications(userData.in_app_notifications);
      setEmailNotifications(userData.email_notifications);
    }
  }, [userData]);

  const handleNotificationChange = (type: NotificationType, value: boolean) => {
    if (type === 'inApp') {
      setInAppNotifications(value);
    } else if (type === 'email') {
      setEmailNotifications(value);
    }
  };

  const handleSelectRole = (value: string) => {
    setRole(value as ROLES);

    if (value === ROLES.VAR) {
      setOrganization(undefined);
    }
  };

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newName = e.target.value;
    setName(newName);
    debounceValidation(() => setNameError(validateName(newName)));
  };

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    debounceValidation(() => setEmailError(validateEmail(newEmail)));
  };

  const handleCompanyChange = (
    newValue: string | number | boolean | (string | number | boolean)[] | undefined
  ) => {
    const selectedOrganization = organizationOptions.find((org) => org.value === newValue);

    setOrganization({
      id: newValue as string,
      name: selectedOrganization?.label || ''
    });
  };

  const handlePositionChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newCompany = e.target.value;
    setPosition(newCompany);
  };

  const handleNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newNumber = e.target.value;
    setNumber(newNumber);
    // Not debouncing here to avoid submit button enabled flash
    setNumberError(validateNumber(newNumber));
  };

  const handleSubmit = async () => {
    try {
      if (id) {
        const updatedFields: UserUpdateParams = {
          id
        };

        if (name !== userData?.name) updatedFields.name = name;

        if (`+1${number}` !== userData?.phone_number) updatedFields.phone_number = `+1${number}`;

        if (position !== userData?.position_title) updatedFields.position_title = position;

        if (role !== userData?.role) updatedFields.role = role;

        if (inAppNotifications !== userData?.in_app_notifications) {
          updatedFields.in_app_notifications = inAppNotifications;
        }

        if (emailNotifications !== userData?.email_notifications) {
          updatedFields.email_notifications = emailNotifications;
        }

        if (organization?.id !== userData?.organization?.id) {
          updatedFields.organization_id = organization?.id || '';
        }

        await editUser({ ...updatedFields }).unwrap();
      } else {
        await createUser({
          organization_id: organization?.id || '',
          name,
          role: role as ROLES,
          phone_number: `+1${number}`,
          username: email,
          position_title: position,
          password,
          in_app_notifications: inAppNotifications,
          email_notifications: emailNotifications
        }).unwrap();
      }

      setIsConfirmModalOpen(true);
    } catch (e) {
      handleCatch(e);
    }
  };

  const handleConfirm = () => {
    navigate(ROUTES.USERS);
  };

  const handleDelete = async () => {
    if (id) {
      try {
        await deleteUser({ id }).unwrap();

        setLoading(true);
        setIsDeleteModalOpen(false);
        setTimeout(() => {
          setLoading(false);
          handleConfirm();
        }, 3000);
      } catch (e) {
        handleCatch(e);
        setIsDeleteModalOpen(false);
        setLoading(false);
      }
    }
  };

  const handleDeactivate = async () => {
    if (id) {
      try {
        await deactivateUser({ id }).unwrap();

        setLoading(true);
        setIsDeleteModalOpen(false);
        setTimeout(() => {
          setLoading(false);
          setIsConfirmModalOpen(true);
        }, 3000);
      } catch (e) {
        handleCatch(e);
        setIsDeleteModalOpen(false);
        setLoading(false);
      }
    }
  };

  const onReactivate = async () => {
    if (id) {
      try {
        setLoading(true);
        await reactivateUser({ id }).unwrap();
        setIsActivateModalOpen(false);

        setTimeout(() => {
          setLoading(false);
          setIsConfirmModalOpen(true);
        }, 3000);
      } catch (e) {
        handleCatch(e);
        setLoading(false);
        setIsActivateModalOpen(false);
      }
    }
  };

  const handleBlur =
    (validator: (value: string) => string, setter: (error: string) => void) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setter(validator(value));
    };

  const resetForm = () => {
    setName('');
    setEmail('');
    setOrganization({ name: '', id: '' });
    setPosition('');
    setNumber('');
    setRole(undefined);
    setPassword('');
    setConfirmPassword('');
  };

  const handleCancel = () => {
    if (id) {
      setName(userData?.name || '');
      setEmail(userData?.username || '');
      setOrganization({
        id: userData?.organization.id || '',
        name: userData?.organization.name || ''
      });
      setPosition(userData?.position_title || '');
      setNumber(userData?.phone_number.replace('+1', '') || '');
      setRole(userData?.role || undefined);
      setPassword('');
      setConfirmPassword('');
    } else {
      resetForm();
    }
  };

  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    debounceValidation(() => setPasswordError(validatePassword(newPassword)));

    if (newPassword !== confirmPassword && confirmPassword.length) {
      setConfirmPasswordError('Passwords must match');
    } else {
      setConfirmPasswordError('');
    }
  };

  const handleConfirmPassword = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setConfirmPassword(value);

    if (value !== password) {
      setConfirmPasswordError('Passwords must match');
    } else {
      setConfirmPasswordError('');
    }
  };

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const handleOpenDeleteModal = () => {
    setIsDeleteModalOpen(true);
  };

  const handleCloseDeactivateModal = () => {
    setIsDeactivateModalOpen(false);
  };

  const handleOpenDeactivateModal = () => {
    setIsDeactivateModalOpen(true);
  };

  const handleCloseConfirmModal = () => {
    setIsConfirmModalOpen(false);

    if (!id) resetForm();
  };

  const handleCloseActivateModal = () => {
    setIsActivateModalOpen(false);
  };

  const handleOpenActivateModal = () => {
    setIsActivateModalOpen(true);
  };

  const isFormValid = useMemo(() => {
    const commonValidations =
      !nameError &&
      !emailError &&
      !numberError &&
      role &&
      ((role as ROLES) === ROLES.VAR || (role !== ROLES.VAR && organization?.id)) &&
      name &&
      email &&
      position &&
      number;

    const passwordValidations = id
      ? true // No password validation required for edit
      : password && confirmPassword && areAllCriteriaMet && password === confirmPassword;

    return commonValidations && passwordValidations;
  }, [
    nameError,
    emailError,
    numberError,
    role,
    organization?.id,
    position,
    name,
    email,
    number,
    password,
    confirmPassword,
    areAllCriteriaMet,
    id
  ]);

  const isModified = useMemo(
    () =>
      name !== userData?.name ||
      email !== userData?.username ||
      `+1${number}` !== userData?.phone_number ||
      position !== userData?.position_title ||
      role !== userData?.role ||
      organization?.id !== userData?.organization?.id ||
      inAppNotifications !== userData?.in_app_notifications ||
      emailNotifications !== userData?.email_notifications ||
      (!!password.length && !!confirmPassword.length),
    [
      name,
      email,
      number,
      position,
      role,
      organization?.id,
      inAppNotifications,
      emailNotifications,
      password.length,
      confirmPassword.length,
      userData
    ]
  );

  const renderError = useMemo(() => {
    if (errorCreate || errorUpdate || errorDelete || errorReactivate || errorDeactivate) {
      return <div className="user-form-error-message">Something went wrong</div>;
    }

    return null;
  }, [errorCreate, errorUpdate, errorDelete, errorReactivate]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="user-form">
      <div className="user-form-title-container">
        <h1 className="user-form-title">{id ? 'Edit' : 'Create'} User</h1>
        <NavLink to={ROUTES.USERS} className="user-form-link">
          Back
        </NavLink>
      </div>

      <div className="user-form-inputs-wrapper">
        <h5 className="user-form-section-title">Information</h5>

        <div className="user-form-inputs-container">
          <InputField
            type="text"
            value={name}
            onChange={handleNameChange}
            id="name"
            label="Name *"
            error={nameError}
            showErrorBlock
            onBlur={handleBlur(validateName, setNameError)}
            success={!nameError && !!name.length}
          />

          <Select
            disabled={
              (currentUser?.role !== ROLES.VAR && userData?.role === ROLES.VAR) ||
              role === ROLES.VAR
            }
            options={organizationOptions}
            value={organization?.id}
            onChange={handleCompanyChange}
            id="organization"
            label="Organization"
          />

          <InputField
            disabled={!!(id && userData?.username)}
            type="email"
            value={email}
            onChange={handleEmailChange}
            id="email"
            label="Email Address *"
            error={emailError}
            showErrorBlock
            onBlur={handleBlur(validateEmail, setEmailError)}
          />

          <InputField
            type="text"
            value={position}
            onChange={handlePositionChange}
            id="position"
            label="Position/Title"
            showErrorBlock
            success={!!position.length}
          />

          <InputField
            type="text"
            value={number}
            onChange={handleNumberChange}
            id="number"
            placeholder="5551231234"
            label="Phone Number *"
            error={numberError}
            showErrorBlock
            onBlur={handleBlur(validateNumber, setNumberError)}
            success={!numberError && !!number.length}
          />

          <Select
            label="Role"
            value={role}
            onChange={(value) => {
              handleSelectRole(value as string);
            }}
            id="select-role"
            options={[
              ...(currentUser?.role === ROLES.VAR ? [{ value: ROLES.VAR, label: 'VAR' }] : []),
              { value: ROLES.LOCAL_ADMIN, label: 'Local Admin' },
              { value: ROLES.VIEWER, label: 'Viewer' }
            ]}
          />

          {!id && (
            <div className="user-form-pass-and-reqs-container">
              <InputField
                type="password"
                value={password}
                onChange={handlePasswordChange}
                id="password"
                label="Temporary Password (8-20 Characters) *"
                isPassword
                error={passwordError}
                showErrorBlock
                success={areAllCriteriaMet}
              />
              <ul className="user-form-requirements">
                <Criteria isCriteriaMet={criteria.minLength} message="Minimum 8 characters" />
                <Criteria
                  isCriteriaMet={criteria.uppercase}
                  message="Includes an uppercase character"
                />
                <Criteria
                  isCriteriaMet={criteria.lowercase}
                  message="Includes a lowercase character"
                />
                <Criteria
                  isCriteriaMet={criteria.specialChar}
                  message="Includes a special character"
                />
                <Criteria isCriteriaMet={criteria.number} message="Includes a number" />
              </ul>
            </div>
          )}

          {!id && (
            <InputField
              type="password"
              value={confirmPassword}
              onChange={handleConfirmPassword}
              id="confirm-password"
              label="Confirm Temporary Password *"
              isPassword
              error={confirmPasswordError}
              showErrorBlock
              className="align-start"
              success={password === confirmPassword && !!confirmPassword.length}
            />
          )}
        </div>

        <NotificationsCheckboxGroup
          inAppNotifications={inAppNotifications}
          emailNotifications={emailNotifications}
          handleNotificationChange={handleNotificationChange}
        />

        {renderError}
        <div className="user-form-buttons-container">
          <Button
            variant="primary"
            disabled={!isFormValid || (!!id && !isModified)}
            onClick={handleSubmit}
          >
            Save Changes
          </Button>
          <Button
            disabled={!isFormValid || (!!id && !isModified)}
            variant="secondary"
            onClick={handleCancel}
          >
            Cancel
          </Button>

          {id && !isMe && userData?.active && (
            <Button
              variant="delete"
              className="user-form-delete-button"
              onClick={handleOpenDeactivateModal}
            >
              Deactivate User
            </Button>
          )}

          {id && !isMe && userData?.active === false && (
            <Button
              variant="primary"
              className="user-form-delete-button"
              onClick={handleOpenActivateModal}
            >
              Reactivate User
            </Button>
          )}

          {id && !isMe && currentUser?.role === ROLES.VAR && (
            <Button
              variant="delete"
              className="user-form-delete-button"
              onClick={handleOpenDeleteModal}
            >
              Delete User
            </Button>
          )}
        </div>
      </div>

      <ConfirmModal
        isOpen={isDeleteModalOpen}
        onClose={handleCloseDeleteModal}
        onConfirm={handleDelete}
        message="Are you sure you want to permanently delete this user? This action cannot be undone."
      />

      <ConfirmModal
        isOpen={isDeactivateModalOpen}
        onClose={handleCloseDeactivateModal}
        onConfirm={handleDeactivate}
        message="Are you sure you want to deactivate this user?"
      />

      <ConfirmModal
        isOpen={isConfirmModalOpen}
        onClose={handleCloseConfirmModal}
        onConfirm={handleConfirm}
        message={`User successfully ${id ? 'modified' : 'created'}`}
        cancelText="Stay on this page"
        confirmText="Go to all users"
        variant="primary"
      />

      <ConfirmModal
        isOpen={isActivateModalOpen}
        onClose={handleCloseActivateModal}
        onConfirm={onReactivate}
        variant="primary"
        message="Are you sure you want to reactivate this user?"
      />
    </div>
  );
};

export default UserForm;
