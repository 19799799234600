import React, { FC, useEffect, useRef, useState, KeyboardEvent } from 'react';
import classNames from 'classnames';

import Checkbox from '../Checkbox/Checkbox';

import './Select.scss';

interface SelectFieldProps {
  label?: string;
  value: string | number | boolean | Array<string | number | boolean> | undefined;
  onChange: (
    value: string | number | boolean | Array<string | number | boolean> | undefined
  ) => void;
  id: string;
  options: Array<{ value: string | number | boolean; label: string }>;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
  multiple?: boolean;
  onClose?: () => void;
  onOpen?: () => void;
}

const Select: FC<SelectFieldProps> = ({
  label,
  value,
  onChange,
  id,
  options,
  className,
  disabled,
  multiple,
  onClose,
  onOpen,
  placeholder = 'Select an option'
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [focusedIndex, setFocusedIndex] = useState(-1);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const optionsListRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    setFocusedIndex(-1);
  }, [isOpen, options]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        if (isOpen && onClose) {
          onClose();
        }

        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [isOpen, onClose]);

  useEffect(() => {
    if (isOpen && optionsListRef.current && focusedIndex >= 0) {
      const optionElement = optionsListRef.current.children[focusedIndex];

      if (optionElement) {
        const dropdownRect = optionsListRef.current.getBoundingClientRect();
        const optionRect = optionElement.getBoundingClientRect();

        if (optionRect.bottom > dropdownRect.bottom) {
          optionsListRef.current.scrollTop += optionRect.bottom - dropdownRect.bottom;
        }

        if (optionRect.top < dropdownRect.top) {
          optionsListRef.current.scrollTop -= dropdownRect.top - optionRect.top;
        }
      }
    }
  }, [focusedIndex, isOpen]);

  const toggleDropdown = () => {
    setIsOpen((prev) => {
      if (!prev) {
        onOpen?.();
      } else {
        onClose?.();
      }

      return !prev;
    });
  };

  const getSelectedOptionLabel = () => {
    if (multiple && Array.isArray(value)) {
      return (
        options
          .filter((option) => value.includes(option.value))
          .map((option) => option.label)
          .join(', ') || 'Select options'
      );
    } else {
      return options.find((option) => option.value === value)?.label || placeholder;
    }
  };

  const handleSelect = (
    optionValue: string | number | boolean,
    e?: React.MouseEvent<HTMLLIElement>
  ) => {
    if (multiple) {
      e?.stopPropagation();
      const tagName = (e?.target as HTMLElement).tagName;

      if (tagName === 'INPUT' || tagName === 'SPAN') {
        return;
      }

      const selectionArray = Array.isArray(value) ? value : [];
      const newValue = selectionArray.includes(optionValue)
        ? selectionArray.filter((val) => val !== optionValue)
        : [...selectionArray, optionValue];

      onChange(newValue);
    } else {
      onChange(optionValue);
    }
  };

  const handleCheckboxChange = (isChecked: boolean, optionValue: string | number | boolean) => {
    const selectionArray = Array.isArray(value) ? value : [];

    let newValue;

    if (isChecked) {
      newValue = selectionArray.includes(optionValue)
        ? selectionArray
        : [...selectionArray, optionValue];
    } else {
      newValue = selectionArray.filter((val) => val !== optionValue);
    }

    onChange(newValue);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    switch (event.key) {
      case 'ArrowDown':
        event.preventDefault();
        setIsOpen(true);
        setFocusedIndex((prevIndex) =>
          prevIndex < options.length - 1 ? prevIndex + 1 : prevIndex
        );
        break;
      case 'ArrowUp':
        event.preventDefault();
        setFocusedIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : 0));
        break;
      case 'Enter':
        event.preventDefault();
        setIsOpen(true);

        if (focusedIndex >= 0 && focusedIndex < options.length) {
          const optionValue = options[focusedIndex].value;

          if (multiple) {
            const isChecked = Array.isArray(value) && value.includes(optionValue);
            handleCheckboxChange(!isChecked, optionValue);
          } else {
            handleSelect(optionValue);
          }
        }
        break;
      case 'Escape':
        setIsOpen(false);
        onClose?.();
        break;
      default:
        break;
    }
  };

  return (
    <div className={classNames('select', className)} ref={dropdownRef}>
      {label && (
        <div className="label-container">
          <label htmlFor={id}>{label}</label>
        </div>
      )}

      <div
        className={classNames('select-container', { open: isOpen, disabled })}
        onClick={!disabled ? toggleDropdown : undefined}
        onKeyDown={!disabled ? handleKeyDown : undefined}
        tabIndex={!disabled ? 0 : undefined}
        role="button"
        aria-haspopup="listbox"
        aria-expanded={isOpen}
      >
        <div className="selected-value">{getSelectedOptionLabel()}</div>
        {isOpen && !disabled && (
          <ul className="dropdown-options" ref={optionsListRef}>
            {options.map((option, index) => (
              <li
                key={`${option.label}-${index}`}
                onClick={(e) => handleSelect(option.value, e)}
                onKeyDown={(e) => e.key === 'Enter' && handleSelect(option.value)}
                tabIndex={-1}
                role="option"
                aria-selected={value === option.value}
                className={classNames('select-row', { focused: index === focusedIndex })}
              >
                {multiple && (
                  <Checkbox
                    checked={Array.isArray(value) ? value.includes(option.value) : false}
                    onChange={(checked) => handleCheckboxChange(checked, option.value)}
                  />
                )}
                {option.label}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default Select;
