import { Link } from 'react-router-dom';
import React, {
  ChangeEvent,
  KeyboardEvent,
  FocusEvent,
  FC,
  useState,
  ReactNode,
  useEffect
} from 'react';
import classNames from 'classnames';

import { ReactComponent as ShowPassword } from '../../assets/show-password.svg';
import { ReactComponent as HidePassword } from '../../assets/hide-password.svg';
import { ReactComponent as CopyIcon } from '../../assets/copy-icon.svg';

import './InputField.scss';

interface InputFieldProps {
  label: string;
  value?: string | number;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
  onKeyUp?: (e: KeyboardEvent<HTMLInputElement>) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  type: string;
  id: string;
  linkText?: string;
  link?: string;
  error?: string;
  success?: boolean;
  className?: string;
  disabled?: boolean;
  showErrorBlock?: boolean;
  isExternalLink?: boolean;
  isPassword?: boolean;
  options?: Array<{ value: string | number; label: string }>;
  placeholder?: string;
  isCopyable?: boolean;
  icon?: ReactNode;
  additionalIcon?: ReactNode;
}

const InputField: FC<InputFieldProps> = ({
  label,
  placeholder,
  value,
  onChange,
  onKeyDown,
  onKeyUp,
  onBlur,
  type,
  id,
  isExternalLink,
  link,
  linkText,
  disabled,
  isPassword,
  error,
  success,
  className,
  icon,
  additionalIcon,
  isCopyable = false,
  showErrorBlock = false
}) => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    if (isCopied) {
      const timer = setTimeout(() => {
        setIsCopied(false);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [isCopied]);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const copyToClipboard = async () => {
    if (value) {
      await navigator.clipboard.writeText(value.toString());
      setIsCopied(true);
    }
  };

  const renderLink = () => {
    if (!link || !linkText) {
      return null;
    }

    return isExternalLink ? (
      <a href={link} className="link" rel="noopener noreferrer">
        {linkText}
      </a>
    ) : (
      <Link to={link} className="link">
        {linkText}
      </Link>
    );
  };

  const renderIcon = () => {
    if (isPassword) {
      return (
        <span
          onClick={togglePasswordVisibility}
          className="password-eye"
          aria-label={passwordVisible ? 'Hide password' : 'Show password'}
        >
          {passwordVisible ? <HidePassword /> : <ShowPassword />}
        </span>
      );
    } else if (icon) {
      return (
        <span className="suffix-icon" aria-label="icon">
          {icon}
        </span>
      );
    } else if (isCopyable) {
      return (
        <div className="copy-icon">
          <span onClick={copyToClipboard} aria-label="Copy to clipboard">
            {isCopied ? <span className="tooltip">Copied!</span> : <CopyIcon />}
          </span>
          {additionalIcon}
        </div>
      );
    }
  };

  return (
    <div className={classNames('input-field', className)}>
      {label && (
        <div className="label-container">
          <label htmlFor={id}>{label}</label>

          {renderLink()}
        </div>
      )}

      <div
        className={classNames('input-container', { error, success, ['is-copyable']: isCopyable })}
      >
        <input
          id={id}
          type={isPassword && passwordVisible ? 'text' : type}
          value={value}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
          onBlur={onBlur}
          disabled={disabled}
          placeholder={placeholder}
        />

        {renderIcon()}
      </div>

      {showErrorBlock && (
        <div className={classNames('error-message', { visible: error })}>{error}</div>
      )}
    </div>
  );
};

export default InputField;
