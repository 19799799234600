import React, { FC, InputHTMLAttributes } from 'react';
import classNames from 'classnames';

import './RadioInput.scss';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  onCheckedChange?: (value: boolean) => void;
}

const RadioInput: FC<Props> = ({ className, checked, onCheckedChange, ...props }) => {
  const handleChange = () => {
    onCheckedChange?.(!checked);
  };

  return (
    <div className={classNames(className, 'radio-input-container')} onClick={handleChange}>
      <input {...props} type="radio" className="radio-input" checked={checked} />
      <label className="radio-input-label"></label>
    </div>
  );
};

export default RadioInput;
