import { OrganizationNameAndID } from './organizations';

// disabling eslint here because it's not recognizing this enum is being used in other files
/* eslint-disable no-unused-vars */
export enum ROLES {
  VAR = 'VAR_ADMIN',
  LOCAL_ADMIN = 'ADMIN',
  VIEWER = 'VIEWER'
}

export type User = {
  id: string;
  active?: boolean;
  name: string;
  username: string;
  organization: OrganizationNameAndID;
  position_title: string;
  phone_number: string;
  role: ROLES;
  created_at: string;
  updated_at: string;
  in_app_notifications: boolean;
  email_notifications: boolean;
};
